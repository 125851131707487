

import { FooterAddress, FooterTitle, Input, LinkPage, List, Listitens, NewsLetter, NewsLetterTitle, TrabbeLogo, TrabbeWhatsApp, TrabbeWhatsAppIcon, TrabbeWhatsAppLabel, TrabbeWorks, TrabberMail, TrabberPhone, WrapperButton, WrapperFooter, WrapperNewsInput } from "./styles"
import Logo from '../../assets/icons/logo/trabbe_logo_laranja_slogan_horizontal.png'

import WhatsAppIcon from '../../assets/icons/whatsapp.png'
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";


export const Footer = (padding) => {
    const scriptLoaded = useRef(false);
    const location = useLocation();

    useEffect(() => {
      loadRDStationForm()
     
    }, []);

   function loadRDStationForm() {
    const script = document.createElement('script');
    script.src = 'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js';
    script.type = 'text/javascript';
    script.async = true;
  
    document.body.appendChild(script);
  
    const handleScriptLoad = () => {
      if (!window.RDStationFormsLoaded) {
        new window.RDStationForms('formulario-website-newsletter-0c61ae907eb6d92f7aa4', 'UA-235204449-1').createForm();
        window.RDStationFormsLoaded = true;
        setTimeout(() => {
            var inputElement = document.getElementById("rd-email_field-lqxuk9o7");
            if(inputElement) {
                inputElement.placeholder = "Insira seu e-mail para receber nossas novidades";
            }
        }, 1000)
    }
};

script.addEventListener('load', handleScriptLoad);



  
    // Limpa o script e remove o ouvinte de eventos quando o componente é desmontado

    
    return () => {
      document.body.removeChild(script);
      script.removeEventListener('load', handleScriptLoad);
     
    };
}
    return (
        <WrapperFooter padding={padding}>
            <Listitens>
                <List className="logo">
                    <TrabbeLogo src={Logo} />
                </List>
                <List>
                    <FooterTitle>A empresa</FooterTitle>
                    <LinkPage to={'/quem-somos'} isActive={location.pathname === '/quem-somos'}>Quem somos</LinkPage>
                    <LinkPage to={'https://www.youtube.com/watch?v=HLfDMRi9urc'} target="_blank">Nosso manifesto</LinkPage>
                    <LinkPage to={'https://www.youtube.com/watch?v=nR67Djn3FNA'} target="_blank">Nosso propósito</LinkPage>
                    <LinkPage to={'https://jobber.trabbe.com/'} target="_blank">Portal do anunciante</LinkPage>
                    <LinkPage to={'/blog'}>Nosso blog</LinkPage>
                </List>
                <List>
                    <FooterTitle>Produtos</FooterTitle>
                    <LinkPage to={'/merc'} isActive={location.pathname === '/merc'}>Merc</LinkPage>
                    <LinkPage to={'/corp'} isActive={location.pathname === '/corp'}>Corp</LinkPage>
                    <LinkPage to={'/dados'} isActive={location.pathname === '/dados'}>Dados</LinkPage>
                </List>
                <List>
                    <FooterTitle>Legal</FooterTitle>
                    <LinkPage to={'/politicas&privacidade'} isActive={location.pathname === '/politicas&privacidade'}>Políticas de privacidade</LinkPage>
                    <LinkPage to={'/termos&condicoes'} isActive={location.pathname === '/termos&condicoes'}>Termos e condições</LinkPage>
                </List>
                <List>
                    <FooterTitle>Atendimento</FooterTitle>
                    <TrabbeWorks>De segunda a sexta feira das 
                        09:00 às 18:00</TrabbeWorks>
                    <TrabberMail>
                    E-mail:<br/>
                    <Link to="mailto:sac@trabbe.com?subject=Assunto do Email&body=Corpo da Mensagem"><span>sac@trabbe.com</span></Link>
                    </TrabberMail>
                    <TrabbeWhatsApp to='https://api.whatsapp.com/send?phone=5511945397281' target="_blank">
                    <TrabbeWhatsAppIcon src={WhatsAppIcon} alt='WhatsApp Icon' />
                    <TrabbeWhatsAppLabel>(11) 94539-7281</TrabbeWhatsAppLabel>
                    </TrabbeWhatsApp>
                    {/* <TrabberPhone>
                    Telefone: <span>0800 000 000 0000</span>
                    </TrabberPhone> */}
                </List>
            </Listitens>
            <NewsLetter>
                <NewsLetterTitle>Receba todas as novidades assinando nossa newsletter</NewsLetterTitle>
                <div id="main_element">
                    <div class="buttonrd"role="main" id="formulario-website-newsletter-0c61ae907eb6d92f7aa4"></div>
                </div>
                
                
               
            </NewsLetter>
            <FooterAddress>
            <span>
            2022 Trabbe - Todos os direitos reservados Trabbe - Trabbe Tecnologia LTDA - CNPJ : 40.685.382/0001-59<br />R. Santa Cruz, 722 - 5º andar - Vila Mariana - São Paulo - SP, 04122-0000
            </span>
            </FooterAddress>
        </WrapperFooter>
    )
}



export default React.memo(Footer)