import React, { useEffect, useRef, useState } from 'react';

import BackgroundBarra from '../../assets/images/content_main.png'
import AppExample from '../../assets/images/app_example.png'
import Elemento from '../../assets/images/elemento.png'


import DownloadGoogleImage from '../../assets/images/download_app/google.png'
import DownloadAppleImage from '../../assets/images/download_app/apple.png'



import Leaf from '../../assets/images/trabbe_works/leaf.png'
import Person from '../../assets/images/trabbe_works/pessoa.png'
import Detail from '../../assets/images/trabbe_works/trabbe_details.png'

import PersonAnuncia from '../../assets/images/trabbe_announce/person.png'
import BlueLeaf from '../../assets/images/trabbe_announce/leaf_blue.png'
import IconAnnunce from '../../assets/images/trabbe_announce/announce-icon.png'


import WhiteLeafImage from '../../assets/images/trabbe_app/white_leaf.png'


import {
    Content,
    ConteudoDigital,
    Merc,
    RotaText,
    PlataformaText,
    Download,
    AppImage,
    WhoTrabbeWorks,
    WrapperLeaf,
    WrapperInformation,
    PersonImage,
    WhoTrabbeWrapper,
    WhoTrabbeTitle,
    ContentText,
    WhoTrabbeDetails,
    DownloadApp,
    DownloadAppText,
    WhoTrabbeAnnounce,
    WhoTrabbeAnnounceTitle,
    WhoTrabbeAnnounceServe,
    WhoTrabbeAnnouceParagraph,
    WhoTrabbeWrapperCount,
    WhoTrabbeIcon,
    WhoTrabberNumber,
    WhoTrabbeAnunciaPerson,
    WhoTrabbeBlueLeaf,
    DownloadAppImage,
    TrabbeApp,
    TrabbeAppTitle,
    WhiteLeaf,
    WrapperDownload,
    DownloadGoogle,
    DownloadApple,
    LinkPage,
    WrapperTrabbe,
    WhoTrabbeAnnounceWrapper,
    ElementoImage,
    WhoTrabbeDetailsDownload,
} from './styles'

import { SocialNetworks } from '../../components/social_networks';
import Carousel from '../../components/carrousel_snap/index';
import Footer from '../../components/footer';

// importa o axios
import axios from 'axios';

import {
    AnimatePresence,
    motion,
    useMotionValue,
    useMotionValueEvent, 
    useScroll,
} from 'framer-motion';
import { click } from '@testing-library/user-event/dist/click';
import HeaderComponent from '../../components/header'


const cardVariants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 50,
      rotate: -10,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8
      }
    }
  };


const MercPage = () => {
    const scrollRef = useRef(null)
    const {scrollY} = useScroll()
    const [overflow, setOverflow] = useState(true)
    const [tamanho, setTamanho] = useState({ width: window.innerWidth, height: window.innerHeight });
    
    const [hiddenContent, setHiddenContent] = useState(false)
    const [hiddenWhoTrabbe, setHiddenWhoTrabbe] = useState(false)
    const [hiddenWhoAnnunce, setWhoAnnunce] = useState(false)
    const [hiddenCellphone, setCellPhone] = useState(false)
    const [totalTrabbers, setTotalTrabbers] = useState(0);
    const [hiddenCounter, setHiddenCounter] = useState(false)
    const [prevTotalUsers, setPrevTotalUsers] = useState(null);
    const [targetCount, setTargetCount] = useState();

    const COUNT_INTERVAL_TRABBERS = 5000;



    const[showCounterNumber, setShowCounterNumber] = useState(false);

    const updateTotalTrabbers = async () => {
        try {
            const response = await axios.get('https://api.trabbe.com/pessoafisica/totaluser');
            setTargetCount(response.data);
        } catch (error) {
            console.log('Erro ao buscar o total de trabbers', error);
        }
    
        const topElement = document.getElementById('top'); // ou qualquer ID que você esteja usando para o topo
        topElement.scrollIntoView({ behavior: 'smooth' });
        setTotalTrabbers(0)

        const subs = scrollY.on('change', (latest) => {
            const previous = scrollY.getPrevious();
            if(latest > 0) {
                setHiddenContent(false)
            }
            if(latest > 22) {
                setHiddenWhoTrabbe(true)
            }
            if(latest > 699) {
                setWhoAnnunce(true)
            }

            if(latest > 710) {
                setShowCounterNumber(true)
            }
            if(latest > 780) {
                setCellPhone(true)
            }
            if(latest > 1520) {
                setHiddenWhoTrabbe(true)
                setOverflow(false)
            }
            if(latest > 1403) {
            }
        })
        if(showCounterNumber) {
            getTrabbersCount()
        }
        handleResize()
        return () => subs()
    }
 
    useEffect(() => {
        updateTotalTrabbers()
        
    }, [scrollY, showCounterNumber]); // O array de dependências vazio garante que o efeito é executado apenas uma vez durante a montagem e desmontagem do componente
    
    useMotionValueEvent(scrollY, "change", (latest) => {
    })

    const handleResize = () => {
        setTamanho({ width: window.innerWidth, height: window.innerHeight });

         // Adiciona um ouvinte de evento para a mudança de tamanho da janela
        window.addEventListener('resize', handleResize);
    
        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    };

    function getTrabbersCount() {
        const increment = 2750;
        const interval = 350;
    
        let currentCount = 2500;
    
        const intervalId = setInterval(() => {
            setTotalTrabbers((prevCount) => {
                const newCount = prevCount + increment;
    
                // Verifica se o número ultrapassou ou atingiu o objetivo
                if (newCount >= targetCount) {
                    clearInterval(intervalId);
                    return targetCount; // Define o valor final exato
                }
    
                return newCount;
            });
        }, interval);
    
        // Limpa o interval
    }    
      
    //     return (
    //       <WhoTrabberNumber
    //         key={index}
    //         initial={{ opacity: shouldAnimate ? 1 : 1, y: shouldAnimate ? 0 : 0 }}
    //         animate={{ opacity: 1, y: shouldAnimate ? 0 : 0 }}
    //       >
    //         {digit}
    //       </WhoTrabberNumber>
    //     );
    //   };

    //   const userDigits = totalTrabbers
    //   .toString()
    //   .padStart(5, '0')
    //   .split('')
    //   .map((digit, index) => (
    //     <AnimatedDigit
    //     key={index}
    //     digit={digit}
    //     index={index}
    //     prevDigit={prevTotalUsers ? prevTotalUsers.toString()[index] : null}
    //   />
    //   ));
  
    return (
            <>
            
            <Content
            id='top' 
            overflow={overflow}
            ref={scrollRef}>

            <section
                class="hero is-light is-fullheight mec-banner">
                <div class="hero-body">
                    <div class='container'>
                    <div class='columns is-vcentered is-justify-content-space-between'>
                        <div class='column is-half' style={{marginTop: '80px'}}>
                            <Merc
                                variants={{
                                    visible: {
                                        x: 0, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .4
                                        }}
                                }}
                                initial={{ x: -50, opacity: 0}}
                                animate={hiddenContent ? 'hidden' : 'visible'}
                                transition={{delay: .4}}
                                
                                >MERC</Merc>
                            <RotaText
                                variants={{
                                    visible: {
                                        x: 0, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .4
                                        }},
                                }}
                                initial={{ x: -50, opacity: 0}}
                                animate={hiddenContent ? 'hidden' : 'visible'}
                            >A rota do <span>Comércio Digital</span></RotaText>
                            <PlataformaText
                                variants={{
                                    visible: {
                                        x: 0, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .4
                                        }}
                                }}
                                initial={{ x: -50, opacity: 0}}
                                animate={hiddenContent ? 'hidden' : 'visible'}
                                transition={{delay: .4}}
                            
                            >
                            Plataforma de trabalho on-line que veio para melhorar a vida das pessoas.
                            </PlataformaText>
                            <WrapperDownload>
                                <LinkPage to={'https://play.google.com/store/apps/details?id=com.apptrabbe&pli=1'} target='_blank'>
                                    <DownloadGoogle src={'https://trabbe-upload.s3.us-east-2.amazonaws.com/email/playstore.png'}></DownloadGoogle>
                                </LinkPage>
                                <LinkPage className='apple_download' to={'https://apps.apple.com/br/app/trabbe/id1569537685'} target='_blank'>
                                    <DownloadApple src={'https://trabbe-upload.s3.us-east-2.amazonaws.com/email/applestore.png'}></DownloadApple>
                                </LinkPage>
                            </WrapperDownload>
                            </div>
                        <div class='column' style={{marginTop: '80px'}}>
                        <AppImage
                                variants={{
                                    visible: {
                                        opacity: 1, 
                                        transition: {
                                            delay: .6
                                        }
                                    }
                                }}
                                initial={{ opacity: 0 }}
                                animate={hiddenContent ? 'hidden' : 'visible'}
                                src={AppExample} />
                        </div>
                    </div>
                    </div>
                </div>
            </section>


            <section 
                        initial={{ opacity: 1 }}
                        viewport={{ once: true }}
                        class="hero is-light is-fullheight mec-trabbe-works"
                        >
                <div class="hero-body">
                    <WrapperLeaf className='is-hidden-mobile' src={Leaf} />
                    
                    <div class='container'>
                    <div class='columns is-vcentered is-justify-content-space-between'>
                        <div class='column is-half'>
                        <PersonImage
                        variants={{
                                visible: {
                                    x: -100, 
                                    opacity: 0, 
                                    transition: {
                                        delay: .2
                                    }}
                                }}
                            animate={hiddenWhoTrabbe ? 'hidden' : 'visible'}
                            transition={{duration: 1 }} 
                            src={Person} />
                        </div>
                        <div class='column column-2' style={{marginBottom: '150px'}}>
                        <WhoTrabbeTitle 
                        >Para quem trabalha</WhoTrabbeTitle>
                        <ContentText
                        >
                            Com o aplicativo da Trabbe você cria a sua própria agenda, podendo ser sua ocupação principal ou apenas para os momentos disponíveis.
                        </ContentText>
                        <ContentText
                        >
                        A plataforma disponibiliza cursos para aprimorar seu currículo e você terá acesso as avaliações dos seus clientes, recebendo feedbacks importantes que vão ajudar a trilhar seu sucesso.
                        </ContentText>
                        </div>
                    </div>
                    </div>
                </div>
                    <WhoTrabbeDetails className='is-hidden-mobile' src={Detail}></WhoTrabbeDetails>
            </section>
            <DownloadApp className='information-download is-hidden-mobile'>
                <div class="hero-body">
                    <div class='container'>
                    <div class='columns pl-5 ml-5'>
                        <div class='column is-half'>
                            
                            <DownloadAppText>Baixe nosso app agora mesmo, disponível para Android e iOS</DownloadAppText>
                        </div>
                        <div class='column is-flex is-align-content-center column-2'>
                       
                        <LinkPage to={'https://play.google.com/store/apps/details?id=com.apptrabbe&pli=1'} target='_blank'>
                                    <DownloadGoogle src={'https://trabbe-upload.s3.us-east-2.amazonaws.com/email/playstore.png'}></DownloadGoogle>
                                </LinkPage>
                                <LinkPage className='apple_download' to={'https://apps.apple.com/br/app/trabbe/id1569537685'} target='_blank'>
                                    <DownloadApple src={'https://trabbe-upload.s3.us-east-2.amazonaws.com/email/applestore.png'}></DownloadApple>
                                </LinkPage>
                        </div>
                    </div>
                    </div>
                </div>
                <WhoTrabbeDetailsDownload class='is-hidden-mobile' src={Detail}/>
            </DownloadApp>
            <section 
                        initial={{ opacity: 1 }}
                        viewport={{ once: true }}
                        class="hero is-light is-fullheight mec-who-trabbe-announce"
                        >
                        <div class="hero-body">
                            <WhoTrabbeBlueLeaf className='is-hidden-mobile' src={BlueLeaf} />
                            <div class='container'>
                            <div class='columns'>
                                <div class='column is-half'>
                                <WhoTrabbeAnnounceTitle
                                    variants={{
                                        visible: {
                                            x: -300, 
                                            opacity: 0, 
                                            transition: {
                                                delay: .3
                                            }},
                                            hidden: {
                                                x: 0, 
                                                opacity: 1,
                                                transition: {
                                                    delay: .4
                                            }
                                        }
                                    }}
                                    animate={hiddenWhoAnnunce ? 'hidden' : 'visible'}
                                    initial={{ opacity: 0, x: -300}}
                                    transition={{duration: 2 }}
                                    
                                    >Pra quem anuncia</WhoTrabbeAnnounceTitle>
                                    <WhoTrabbeAnnounceServe>A Trabbe conta com mais de:</WhoTrabbeAnnounceServe>
                                    <WhoTrabbeWrapperCount className='mt-3'>
                                        <WhoTrabbeIcon src={IconAnnunce}/>
                                        <AnimatePresence>
                                            <WhoTrabberNumber
                                            variants={{
                                                visible: {
                                                    y: 0, 
                                                    opacity: 1, 
                                                    transition: {
                                                        delay: .3
                                                    }},
                                                    hidden: {
                                                        y: -200, 
                                                        opacity: 0,
                                                        transition: {
                                                            delay: .4
                                                    }
                                                }
                                            }}
                                            animate={hiddenCounter ? 'hidden' : 'visible'}
                                            
                                            >{totalTrabbers.toString().padStart(5, '0')}</WhoTrabberNumber>
                                        </AnimatePresence>
                                    </WhoTrabbeWrapperCount>
                                    <WhoTrabbeAnnouceParagraph>Usuários qualificados e bem treinados que estão disponíveis para aprender mais sobre seu negócio e dar aquela forcinha para impulsionar seus produtos ou serviços.   
                                    </WhoTrabbeAnnouceParagraph>
                                    <WhoTrabbeAnnouceParagraph>
                                    Com a Trabbe você amplia sua força de vendas e atendimento!
                                    </WhoTrabbeAnnouceParagraph>
                                </div>
                                <div class='column'>
                                <WhoTrabbeAnunciaPerson
                                    variants={{
                                        visible: {
                                            y: -100,
                                            x: 0, 
                                            opacity: 0, 
                                            transition: {
                                                delay: .3
                                            }},
                                            hidden: {
                                                y: 0, 
                                                opacity: 1,
                                                transition: {
                                                    delay: .9
                                            }
                                        }
                                    }}
                                    animate={hiddenWhoAnnunce ? 'hidden' : 'visible'}
                                    initial={{ opacity: 0, y: -300}}
                                    transition={{duration: 3 }}
                                    
                                    src={PersonAnuncia} />
                                </div>
                            </div>
                            </div>
                        </div>
                </section>
            <TrabbeApp
                variants={{
                    visible: {
                        opacity: 0, 
                        transition: {
                            delay: .3
                        }},
                        hidden: {
                            opacity: 1,
                            transition: {
                                delay: .5
                        }
                    }
                }}
                animate={hiddenCellphone ? 'hidden' : 'visible'}
                initial={{ opacity: 0}}
                transition={{duration: 3 }}
            
            >
                <TrabbeAppTitle>Conheça nosso aplicativo</TrabbeAppTitle>
                <Carousel />
                <WhiteLeaf className='is-hidden-mobile' src={WhiteLeafImage} />
            </TrabbeApp>
        </Content>
        {/* </div> */}
        </>
    )
}


export default MercPage