import { motion } from 'framer-motion';
import styled from 'styled-components';
import { colors } from 'utils/colors';


export const Dados = styled(motion.div)`
    background: url(${props => props.backGround}) no-repeat;
    background-size: cover;
`;


export const WrapperDados =  styled(motion.div)`
    color: ${colors.white};
`;

export const TitleDados =  styled(motion.h1)`
    font-size: 40px;
    font-weight: bold;
    max-width: 300px !important;
`;

export const SubTitle =  styled(motion.h2)`
    font-size: 30px;
    margin-top: 16px;
    font-weight: 300;
    line-height: 2.6rem;

    span {
        font-weight: bold;
    }

`;

export const Paragraphy =  styled(motion.p)`
    font-size: 24px;
    font-weight: 400;
    margin-top: 16px;
    max-width: 620px;
`;


export const BrainTrabbe =  styled(motion.img)`
`;

export const BlueBrain = styled(motion.div)`
    background: url(${props => props.backGround}) no-repeat;
    background-size: cover;
    color: ${colors.white};
`;

export const BlueBrainImg = styled(motion.img)`
    position: relative ;
`;


export const WrapperBlueBrain =  styled(motion.div)`
   
`;


export const TitleBlueBrain =  styled(motion.div)`
    font-size: 40px;
    margin-bottom: 40px;
    margin-top: 60px;
    font-weight: bold;
`;

export const ContentBlueBrain =  styled(motion.div)`
    font-size: 24px;
    line-height: 2rem;
    max-width: 800px !important;

    span {
        font-weight: bold;
    }

`;


export const BigData = styled(motion.div)`
    background: url(${props => props.backGround}) no-repeat;
    background-size: cover;
    color: ${colors.white};
`;

export const WrapperBigData = styled(motion.div)`
    
`;

export const BigDataImage = styled(motion.img)`
    position: relative ;
`;


export const TitleBigData = styled(motion.div)`
    font-size: 40px;
    margin-bottom: 40px;
    font-weight: bold;
    line-height: 2.7rem;
`;

export const ContentBigData = styled(motion.div)`
    font-size: 24px;
    line-height: 2.2rem;

`;

export const ListaBigData = styled(motion.ul)`
    list-style: inside;
`;


export const Content = styled(motion.div)`
    width: 100%;
    
    position: relative;

    @media only screen and (min-width: 375px) {
        ${BrainTrabbe} {
            display: block;
        }

        ${Dados} {
            justify-content: center;
        }

        ${TitleDados} {
            font-size: 32px;
        }

        ${SubTitle} {
            font-size: 32px !important;
        }

        ${Paragraphy} {
            font-size: 20px;
        }

        ${BlueBrain} {
            font-size: 20px;
            position: relative;
            
        }

        ${WrapperBlueBrain} {
        }

        ${TitleBlueBrain} {
            font-size: 32px !important;
        }

        ${ContentBlueBrain} {
            font-size: 20px;
        }

        ${BigData} {
            font-size: 32px !important;
        }

        ${WrapperBigData} {
            font-size: 32px;
        }

        ${ContentBigData} {
            font-size: 20px;
            
        }
    }

    @media only screen and (min-width: 425px) {
        ${BrainTrabbe} {
            display: block;
        }

        ${Dados} {
            justify-content: center;
        }

        ${TitleDados} {
            font-size: 32px;
        }

        ${SubTitle} {
            font-size: 32px !important;
        }

        ${Paragraphy} {
            font-size: 20px;
        }

        ${BlueBrain} {
            font-size: 20px;
            position: relative;
            
        }

        ${WrapperBlueBrain} {
        }

        ${TitleBlueBrain} {
            font-size: 32px !important;
        }

        ${ContentBlueBrain} {
            font-size: 20px;
        }

        ${BigData} {
            font-size: 32px !important;
        }

        ${WrapperBigData} {
            font-size: 32px;
        }

        ${ContentBigData} {
            font-size: 20px;
            
        }
    }

    @media only screen and (min-width: 768px) {
        ${BrainTrabbe} {
            display: block;
        }

        ${Dados} {
            justify-content: center;
        }

        ${TitleDados} {
            font-size: 32px;
        }

        ${SubTitle} {
            font-size: 32px !important;
        }

        ${Paragraphy} {
            font-size: 20px;
        }

        ${BlueBrain} {
            font-size: 20px;
            position: relative;
            
        }

        ${WrapperBlueBrain} {
        }

        ${TitleBlueBrain} {
            font-size: 32px;
            margin-top: 100px !important;
        }

        ${ContentBlueBrain} {
            font-size: 20px;
        }

        ${BigData} {
            font-size: 32px !important;
        }

        ${WrapperBigData} {
            font-size: 32px;
        }

        ${ContentBigData} {
            font-size: 20px;
            
        }
    }

    @media only screen and (min-width: 1024px) {
        ${BrainTrabbe} {
            display: block;
        }

        ${Dados} {
            justify-content: center;
        }

        ${TitleDados} {
            font-size: 36px;
        }

        ${SubTitle} {
            font-size: 36px !important;
        }

        ${Paragraphy} {
            font-size: 22px;
        }

        ${BlueBrain} {
            font-size: 22px;
            position: relative;
            
        }

        ${WrapperBlueBrain} {
        }

        ${TitleBlueBrain} {
            font-size: 36px;
            margin-top: 80px;
        }

        ${ContentBlueBrain} {
            font-size: 22px;
        }

        ${BigData} {
            font-size: 36px !important;
        }

        ${WrapperBigData} {
            font-size: 36px;
        }

        ${ContentBigData} {
            font-size: 22px;
            
        }
    }

    @media only screen and (min-width: 1440px) {
        ${BrainTrabbe} {
            display: block;
        }

        ${Dados} {
            justify-content: center;
        }

        ${TitleDados} {
            font-size: 40px;

        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        ${Paragraphy} {
            font-size: 24px;
        }

        ${BlueBrain} {
            font-size: 24px;
            position: relative;
            
        }

        ${WrapperBlueBrain} {
        }

        ${TitleBlueBrain} {
            font-size: 40px;
            margin-top: 60px;
        }

        ${ContentBlueBrain} {
            font-size: 24px;
        }

        ${BigData} {
            font-size: 40px !important;
        }

        ${WrapperBigData} {
            font-size: 40px;
        }

        ${ContentBigData} {
            font-size: 24px;
            
        }
    }
    
`;
