import React from 'react';
import {Routes, Route, Redirect, Navigate, useLocation} from 'react-router-dom'
import MercPage from '../pages/merc';
import CorpPage from '../pages/corp';
import DadosPage from '../pages/dados';
import BlogPage from '../pages/blog';


import {
    RouterContent
} from './styles'
import TermosPoliticies from '../pages/termos_condicoes';
import PoliticiesPrivacity from '../pages/polities_privacity';
import HomePage from '../pages/home';
import Footer from '../components/footer';




const AppRoutes = () => {
    const location = useLocation();

    return (
          <>
             <RouterContent>
             <Routes>
                <Route path="*" element={<Navigate to="/merc" />} />
                <Route path='/quem-somos' element={<HomePage />}></Route>
                <Route index path='/merc' element={<MercPage />}></Route>
                <Route path='/corp' element={<CorpPage />}></Route>
                <Route path='/dados' element={<DadosPage />}></Route>
                <Route path='/blog/' element={<BlogPage />}></Route>
                <Route path='/termos&condicoes' element={<TermosPoliticies />}></Route>
                <Route path='/politicas&privacidade' element={<PoliticiesPrivacity />}></Route>
            </Routes>
           </RouterContent>
           {location.pathname !== '/blog/' ? (
            <Footer />
           ): null}
          </>
    )
}







export default AppRoutes