import { WrapperFooter } from '../../components/footer/styles'
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { colors } from 'utils/colors';
import BackgroundBarra from '../../assets/images/content_main.png'



export const ConteudoDigital = styled(motion.section)`
        background: url(${BackgroundBarra});
        background-size: cover;
        background-position: -1200px 0px ;
`;


export const Merc = styled(motion.h1)`
    font-size: 40px !important;
    background: transparent linear-gradient(108deg, #DC6C61 0%, #381679 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;
    font-weight: bold;
    color: transparent;
    
    // Add more media queries as needed for other screen sizes
`;

export const RotaText = styled(motion.h2)`
    margin-top: 9px;
    padding: 10px 0;
    color: ${colors.blackgray};
    font-weight: 400;
    display: flex;
    flex-direction: column;
    font-size: 40px !important;
    line-height: 1.2;

    span {
        font-weight: bold;
    }

`;

export const PlataformaText = styled(motion.p)`
    margin-top: 16px;
    margin-bottom: 40px;
    font-size: 24px !important;
    font-weight: normal;
    max-width: 477px;


`;

export const WrapperDownload = styled.div`
    display: flex;
    align-items: center;
`;

export const DownloadGoogle = styled.img`
    width: 210px;
    position: relative;
    z-index: 800;

`;

export const DownloadApple = styled.img`
    width: 216px;
    margin-left: 10px;
    font-size: bold;
    position: relative;
    z-index: 800;
    
`;


export const AppImage = styled(motion.img)`
transform: scale(1.1,1.1);
`;

export const ElementoImage = styled(motion.img)`
    float: left;
`;


// Final do conteudo digital



// Inicio Trabbe pra quem trabalha

export const WhoTrabbeWorks = styled(motion.section)`
    background-color: ${colors.main};
    position: relative;
    
    
`;


export const WrapperLeaf = styled.img`
    width: 165px;
    height: 294px;
    position: absolute;
    top: -63px;
    left: 30px;
    z-index: 4000;
`;

export const WrapperInformation = styled.div`
`;

export const PersonImage = styled(motion.img)`
    object-fit: contain;
    position: relative;
    z-index: 2000;

`;

export const WhoTrabbeWrapper = styled.div`

`;

export const WrapperTrabbe = styled.div`
margin-left: 100px;
`;

export const WhoTrabbeTitle = styled(motion.h1)`
    font-size: 40px;
    color: ${colors.white};
    font-weight: bold;
    margin-bottom: 4px;
    
`;

export const ContentText = styled(motion.p)`
    font-size: 24px;
    line-height: 2.1rem;
    max-width: 510px;
    margin-top: 40px;
    text-align: left;
    color: ${colors.white};

`;

export const WhoTrabbeDetails = styled.img`
    position: relative;
    bottom: 190px;
    z-index: 798;
`;

export const WhoTrabbeDetailsDownload = styled.img`
    position: absolute;
    right: 490px;
    z-index: 100;
    filter: grayscale(100%);
    opacity: 0.2;
`;

export const DownloadApp = styled.div`
    width: 100%;
    background-color: #5A248A;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
    height: 160px;
    position: relative;
    bottom: 0;

    .column-2 {
        justify-content: center;
        align-items: center;
    }
`;

export const DownloadAppImage = styled.img`
    width: 445px;
    height: 62px;
    z-index: 2000;
    position: absolute;
    right: 300px;
    z-index: 3000;
`;

export const DownloadAppText = styled.h2`
    font-size: 24px;
    color: ${colors.white};
    width: 392px;
    font-weight: bold;
`;

export const LinkPage = styled(Link)`
    text-decoration: none;
    cursor: pointer;
`;

// Fim trabbe trabalha


// Inicio trabbe anuncia

export const WhoTrabbeAnnounce = styled(motion.section)`
    background-color: ${colors.blueocean};
    position: relative;
`;

export const WhoTrabbeAnnounceWrapper = styled.div`
  overflow: hidden;


`;

export const WhoTrabbeAnnounceTitle = styled(motion.h1)`
    font-size: 40px;
    font-weight: bold;
    color: #000;
    
`;

export const WhoTrabbeAnnounceContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 512px;
    font-size: 24px;
`;

export const WhoTrabbeAnnounceServe = styled.h3`
   color: ${colors.gray};
   font-weight: 500;
   margin-top: 40px;
   font-size: 24px;
`;

export const WhoTrabbeWrapperCount = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
`;

export const WhoTrabbeIcon = styled.img`
    width: 85px;
    margin-right: 20px;
`;

export const WhoTrabberNumber = styled(motion.span)`
    opacity: 1;
    font-size: 5.2rem;
    font-weight: bolder;
    color: transparent;
    background: transparent linear-gradient(108deg, #DC6C61 0%, #381679 100%) 0% 0% no-repeat padding-box;
    -webkit-background-clip: text;

`;


export const WhoTrabbeAnnouceParagraph = styled.p`
    margin: 0px;
    margin-top: 10px;
    text-align: left;
    width: 512px;
    line-height: 1.4;
    font-size: 24px;
    color: ${colors.gray};
`;


export const WhoTrabbeAnunciaPerson = styled(motion.img)`
    position: relative;
    z-index: 901;

`;

export const WhoTrabbeBlueLeaf = styled.img`
    position: absolute;
    top: -95px;
    right: 100px;
    z-index: 600;
`;

// Fim trabbe anuncia


// Inicio Conheca o App

export const TrabbeApp = styled(motion.section)`
    background-color: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
   
    
`;


export const TrabbeAppTitle = styled.h3`
    text-align: center;
    color: #000;
    padding-top: 80px;
    font-size: 40px;
    margin-bottom: 30px;
    font-weight: bold;
`;

export const WhiteLeaf = styled.img`
    position: absolute;
    left: 40px;
    top: -80px;

`;


// Final conehça o App


// Inicio Rodapé

export const FooterTrabbe = styled.div`
 width: 100%;
 height: 100%;

`;


// Final conehça o App



export const Content = styled(motion.div)`
    width: 100%;
    position: relative;

    ${WrapperFooter} {
        padding-top: -400px !important;
    }
    @media only screen and (min-width: 320px){
        ${AppImage} {
    }


    ${Merc} {
    font-size: 32px !important;
    }

    ${RotaText} {
        font-size: 32px !important;
        line-height: 2.6rem;
    }

    ${PlataformaText} {
        width: 100%;
        font-size: 20px !important;
    }

    ${DownloadApple} {
        margin: 0;
    }

    ${WrapperDownload} {
        display: flex;
    }

    .apple_download {
    margin-left: 20px;
    }

    ${WrapperLeaf} {
        display: none;
    }
    ${WrapperTrabbe} {
        top: 0px;
        left: 60px;
    }

    ${WhoTrabbeWrapper} {
        position: relative;
        width: 100% !important;
    }

    ${WhoTrabbeTitle} {
        font-size: 32px;
    }

    ${ContentText} {
        font-size: 20px;
    }

    ${WhoTrabbeDetails} {
        
    }


    ${WhoTrabbeBlueLeaf} {
        display: none;
    }

    ${WhoTrabbeAnnounce} {
        padding: 0 25px;
    }

    ${WhoTrabbeAnnounceTitle} {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 32px;
        margin-top: 60px;
    }

    ${WhoTrabbeAnnounceServe} {
        display: flex;
        align-items: center;
        font-size: 20px;
    }

    ${WhoTrabbeWrapperCount} {
        display: flex;

    }

    ${WhoTrabbeAnnouceParagraph} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        font-size: 20px;
    }

    ${WhoTrabbeIcon} {
    width: 60px !important;
    }

    ${WhoTrabberNumber} {
        font-size: 3.4rem !important;
    }

    ${WhiteLeaf} {
    }

    ${TrabbeAppTitle} {
        font-size: 24px;
        margin-top: 20px;
    }
}

    @media only screen and (min-width: 375px){
    
        ${AppImage} {
    }

    ${Merc} {
    font-size: 32px !important;
    }

    ${RotaText} {
        font-size: 32px !important;
        line-height: 2.6rem;
    }

    ${PlataformaText} {
        width: 100%;
        font-size: 20px !important;
    }

    ${DownloadApple} {
        margin: 0;
    }

    ${WrapperDownload} {
        display: flex;
    }

    .apple_download {
    margin-left: 20px;
    }

    ${WrapperLeaf} {
        display: none;
    }
    ${WrapperTrabbe} {
    top: 0px;
    left: 60px;
    }

    ${WhoTrabbeWrapper} {
        position: relative;
        width: 100% !important;
    }

    ${WhoTrabbeTitle} {
        font-size: 32px;
    }

    ${ContentText} {
        font-size: 20px;
    }

    ${WhoTrabbeDetails} {
        
    }


    ${WhoTrabbeBlueLeaf} {
        display: none;
    }

    ${WhoTrabbeAnnounce} {
        padding: 0 25px;
    }

    ${WhoTrabbeAnnounceTitle} {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 32px;
        margin-top: 60px;
    }

    ${WhoTrabbeAnnounceServe} {
        display: flex;
        align-items: center;
        font-size: 20px;
    }

    ${WhoTrabbeWrapperCount} {
        display: flex;

    }

    ${WhoTrabbeAnnouceParagraph} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        font-size: 20px;
    }

    ${WhoTrabbeIcon} {
    width: 60px !important;
    }

    ${WhoTrabberNumber} {
        font-size: 4rem !important;
    }

    ${WhiteLeaf} {
    }

    ${TrabbeAppTitle} {
        font-size: 24px;
        margin-top: 20px;
    }
    }

    @media only screen and (min-width: 425px){

    ${AppImage} {
    }

    ${Merc} {
    font-size: 32px !important;
    }

    ${RotaText} {
        font-size: 32px !important;
        line-height: 2.6rem;
    }

    ${PlataformaText} {
        width: 100%;
        font-size: 20px !important;
    }

    ${DownloadApple} {
        margin: 0;
    }

    ${WrapperDownload} {
        display: flex;
    }

    .apple_download {
    margin-left: 20px;
    }

    ${WrapperLeaf} {
        display: none;
    }
    ${WrapperTrabbe} {
    top: 0px;
    left: 60px;
    }

    ${WhoTrabbeWrapper} {
        position: relative;
        width: 100% !important;
    }

    ${WhoTrabbeTitle} {
        font-size: 32px;
    }

    ${ContentText} {
        font-size: 20px;
    }

    ${WhoTrabbeDetails} {
        
    }


    ${WhoTrabbeBlueLeaf} {
        display: none;
    }

    ${WhoTrabbeAnnounce} {
        padding: 0 25px;
    }

    ${WhoTrabbeAnnounceTitle} {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 32px;
        margin-top: 60px;
    }

    ${WhoTrabbeAnnounceServe} {
        display: flex;
        align-items: center;
        font-size: 20px;
    }

    ${WhoTrabbeWrapperCount} {
        display: flex;

    }

    ${WhoTrabbeAnnouceParagraph} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        font-size: 20px;
    }

    ${WhoTrabbeIcon} {
    width: 60px !important;
    }

    ${WhoTrabberNumber} {
        font-size: 3.2rem !important;
    }

    ${WhiteLeaf} {
    }

    ${TrabbeAppTitle} {
        font-size: 24px;
        margin-top: 20px;
    }
    }

    @media only screen and (min-width: 768px){

        ${AppImage} {
    }

    ${Merc} {
    font-size: 36px !important;
    }

    ${RotaText} {
        font-size: 36px !important;
        line-height: 2.6rem;
    }

    ${PlataformaText} {
        width: 100%;
        font-size: 20px !important;
    }

    ${DownloadApp} {
        display: none;
    }

    ${DownloadApple} {
        margin: 0;
    }

    ${WrapperDownload} {
        display: flex;
    }

    .apple_download {
    margin-left: 20px;
    }

    ${WrapperLeaf} {
        display: none;
    }
    ${WrapperTrabbe} {
    top: 0px;
    left: 60px;
    }

    ${WhoTrabbeWrapper} {
        position: relative;
        width: 100% !important;
    }

    ${WhoTrabbeTitle} {
        font-size: 36px;
    }

    ${ContentText} {
        font-size: 20px;
    }

    ${WhoTrabbeDetails} {
        
    }


    ${WhoTrabbeBlueLeaf} {
        display: none;
    }

    ${WhoTrabbeAnnounce} {
        padding: 0 25px;
    }

    ${WhoTrabbeAnnounceTitle} {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 36px;
        margin-top: 60px;
    }

    ${WhoTrabbeAnnounceServe} {
        display: flex;
        align-items: center;
        font-size: 22px;
    }

    ${WhoTrabbeWrapperCount} {
        display: flex;
    
    }

    ${WhoTrabbeAnnouceParagraph} {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85%;
        font-size: 22px;
    }

    ${WhoTrabbeIcon} {
    width: 60px !important;
    }

    ${WhoTrabberNumber} {
    font-size: 4rem !important;
    }

    ${WhiteLeaf} {
    }

    ${TrabbeAppTitle} {
        font-size: 24px;
        margin-top: 20px;
    }
    }

    @media only screen and (min-width: 948px){
        ${DownloadApp} {
            display: flex;
        }
    }

    @media only screen and (min-width: 1024px){
        ${AppImage} {
    }


     ${Merc} {
        font-size: 36px !important;
     }

     ${RotaText} {
            font-size: 36px !important;
            line-height: 2.6rem;
        }

     ${PlataformaText} {
         width: 100%;
         font-size: 20px !important;
     }

     ${DownloadApple} {
         margin: 0;
     }

     ${WrapperDownload} {
         display: flex;
     }

     .apple_download {
        margin-left: 20px;
     }

     ${WrapperLeaf} {
            display: block;
    }

     ${WrapperTrabbe} {
        top: 0px;
        left: 60px;
     }

     ${WhoTrabbeWrapper} {
         position: relative;
         width: 100% !important;
     }

     ${WhoTrabbeTitle} {
         font-size: 36px;
        }

        ${ContentText} {
            font-size: 20px;
        }

     ${WhoTrabbeDetails} {
        display: block;
         
     }


     ${WhoTrabbeBlueLeaf} {
         display: block;
     }

     ${WhoTrabbeAnnounce} {
         padding: 0 25px;
    }

     ${WhoTrabbeAnnounceTitle} {
         display: flex;
         text-align: center;
         align-items: center;
         font-size: 36px;
         margin-top: 60px;
     }

     ${WhoTrabbeAnnounceServe} {
         display: flex;
         align-items: center;
         font-size: 22px;
     }

     ${WhoTrabbeWrapperCount} {
         display: flex;
        
     }

     ${WhoTrabbeAnnouceParagraph} {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 85%;
         font-size: 22px;
     }

     ${WhoTrabbeIcon} {
        width: 60px !important;
     }

     ${WhoTrabberNumber} {
        font-size: 4.2rem !important;
     }

     ${WhiteLeaf} {
     }

     ${TrabbeAppTitle} {
         font-size: 36px;
         margin-top: 20px;
     }
    }

    

    @media only screen and (min-width: 1440px){
        ${AppImage} {
    }
        ${Merc} {
        font-size: 40px !important;
        }

        ${RotaText} {
            font-size: 40px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            width: 100%;
            font-size: 24px !important;
        }

        ${DownloadApp} {
            display: flex;
        }

        ${WhoTrabbeDetails} {
            display: block;
        }

        ${DownloadApple} {
            margin: 0;
        }

        ${WrapperDownload} {
            display: flex;
        }

        .apple_download {
        margin-left: 20px;
        }

        ${WrapperLeaf} {
            display: block;
        }

        ${WhoTrabbeTitle} {
         font-size: 40px;
        }

        ${ContentText} {
            font-size: 24px;
        }

        ${WhoTrabbeDetails} {
            
        }


        ${WhoTrabbeAnnounce} {
         padding: 0 25px;
    }

     ${WhoTrabbeAnnounceTitle} {
         display: flex;
         text-align: center;
         align-items: center;
         font-size: 40px;
         margin-top: 60px;
     }

     ${WhoTrabbeAnnounceServe} {
         display: flex;
         align-items: center;
         font-size: 24px;
     }

     ${WhoTrabbeBlueLeaf} {
        display: block;
     }

     ${WhoTrabbeWrapperCount} {
         display: flex;
        
     }

     ${WhoTrabbeAnnouceParagraph} {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 85%;
         font-size: 24px;
     }

     ${WhoTrabbeIcon} {
        width: 85px !important;
     }

     ${WhoTrabberNumber} {
        font-size: 5.2rem !important;
     }

     ${WhiteLeaf} {
     }

     ${TrabbeAppTitle} {
         font-size: 40px;
         margin-top: 20px;
     }

    }

    @media only screen and (min-width: 1980px){
        ${AppImage} {
    }
        ${Merc} {
        font-size: 40px !important;
        }

        ${RotaText} {
            font-size: 40px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            width: 100%;
            font-size: 24px !important;
        }

        ${DownloadApp} {
            display: flex;
        }

        ${WhoTrabbeDetails} {
            display: block;
        }

        ${DownloadApple} {
            margin: 0;
        }

        ${WrapperDownload} {
            display: flex;
        }

        .apple_download {
        margin-left: 20px;
        }

        ${WrapperLeaf} {
            display: block;
        }

        ${WhoTrabbeTitle} {
         font-size: 40px;
        }

        ${ContentText} {
            font-size: 24px;
        }

        ${WhoTrabbeDetails} {
        display: none;
         
     }


        ${WhoTrabbeAnnounce} {
         padding: 0 25px;
    }

     ${WhoTrabbeAnnounceTitle} {
         display: flex;
         text-align: center;
         align-items: center;
         font-size: 40px;
         margin-top: 60px;
     }

     ${WhoTrabbeAnnounceServe} {
         display: flex;
         align-items: center;
         font-size: 24px;
     }

     ${WhoTrabbeBlueLeaf} {
        display: block;
     }

     ${WhoTrabbeWrapperCount} {
         display: flex;
        
     }

     ${WhoTrabbeAnnouceParagraph} {
         display: flex;
         justify-content: center;
         align-items: center;
         width: 85%;
         font-size: 24px;
     }

     ${WhoTrabbeIcon} {
        width: 85px !important;
     }

     ${WhoTrabberNumber} {
        font-size: 5.2rem !important;
     }

     ${WhiteLeaf} {
     }

     ${TrabbeAppTitle} {
         font-size: 40px;
         margin-top: 20px;
     }

    }


   

 
`;