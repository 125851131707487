import { BrowserRouter } from 'react-router-dom';
import HeaderComponent from './components/header';
import AppRoutes from './routes/routes';
import { SocialNetworks } from './components/social_networks';
import { EventProvider } from 'context/context';
import Footer from './components/footer';


function App() {
  return (
    <EventProvider>
      <BrowserRouter>
          <HeaderComponent />
          <AppRoutes />
          <SocialNetworks />
      </BrowserRouter>
    </EventProvider>
    
  );
}

export default App;
