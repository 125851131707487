import axios from 'axios';

const api = axios.create({
    baseURL: 'https://trabbe.com'
})



export const getPost = async (slug) => {
    try {
        const response = await api.get(`/blog/wp-json/wp/v2/posts?slug=${slug}&_embed`)
        return response.data
    } catch (error) {
        console.error(error)
    }
}


export const getPosts = async () => {
    try {
        const response = await api.get('/blog/?rest_route=/wp/v2/posts&_embed&orderby=date&per_page=100')
        return response.data
    } catch (error) {
        console.error(error)
    }
}