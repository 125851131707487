import styled from 'styled-components';
import { colors } from 'utils/colors';
import HeaderBackground from '../../assets/images/header_background.svg'



export const Header = styled.div`
    height: 306px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.blackgray};
    font-size: 40px;
    font-weight: bold;
    font: normal normal bold 40px/40px Poppins;

    span {
        margin-left: 10px;
        color: transparent;
        background: transparent linear-gradient(108deg, #DC6C61 0%, #381679 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
    }
`;


export const Content = styled.div`
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    text-align: left;
    background-color: #fff;
`;

export const Paragraphy = styled.p`
    color: #707070;
    font-size: 24px;
    text-align: left;
    margin-top: 20px;
    font: normal normal normal 24px/32px Roboto;
`;

export const WrapperPage = styled.div`
    @media screen and (min-width: 200px) and (max-width: 768px) {
        ${Content} {
            padding: 0px;
        }
        ${Header} {
            font-size: 20px !important;
        }

        ${Paragraphy} {
            font-size: 20px;
            text-align: left;
        }
    }

    @media screen and (min-width: 769px) and (max-width: 1023px) {
        
        ${Content} {
            padding: 0px;
        }
        ${Header} {
            font-size: 20px !important;
            text-align: left;
        }

        ${Paragraphy} {
            font-size: 20px;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1339px) {
        
        ${Content} {
            padding: 0px;
        }
        ${Header} {
            font-size: 32px !important;
            text-align: justify;
        }

        ${Paragraphy} {
            font-size: 22px;
        }
    }


    @media screen and (min-width: 1440px) and (max-width: 1980px) {
        
        ${Content} {
            padding: 0px;
        }
        ${Header} {
            font-size: 40px !important;
        }

        ${Paragraphy} {
            font-size: 24px;
            text-align: justify;
        }
    }
   

`;