import React, { useEffect, useState } from 'react';
import { AIParagraphy, BackgroundEvolution, ByxHeader, ByxImage, Conjunto, Content, ContentEvolution, EvolutionImageType, EvolutionImageTypeText, EvolutionTextType, Paragraphy, People, Rift, SubTitle, TextTypeMobile, TitleAI, TitleCorp, TitleEvolution, TitleEvolutionContent, TitleEvolutionH, WrapperAI, WrapperCorp, WrapperEvolution, WrapperImageTypeEvolution } from './styles';


import Header from '../../assets/images/byx_header/background.svg'

import BackgroundAI1 from '../../assets/images/background_AI/background1.png'
import BackgroundAI from '../../assets/images/background_AI/background.png'
import ConjuntoImages from '../../assets/images/background_AI/conjunto.png'


import BackgroundEvolutionImage from '../../assets/images/evolution/background.png'

import BxyImg from '../../assets/images/byx_header/byx_v3.png'

import PeopleImage from '../../assets/images/background_AI/people.png'

import RiftImage from '../../assets/images/evolution/rift.png'
import TypeImageElevando from '../../assets/images/evolution/elevando.png'
import TypeImageInovando from '../../assets/images/evolution/inovando.png'
import TypeImageResultados from '../../assets/images/evolution/dinheiro.png'
import TypeImageSuperando from '../../assets/images/evolution/superando.png'



import { SocialNetworks } from '../../components/social_networks';
import Footer from '../../components/footer';
import { useScroll } from 'framer-motion';
import HeaderComponent from '../../components/header';



const CorpPage = () => {

    const {scrollY} = useScroll()
    const [scroll, setScroll] = useState(0)
    const [count, setCount] = useState(0)
    const [tamanho, setTamanho] = useState({ width: window.innerWidth, height: window.innerHeight });

    const [hiddenContent, setHiddenContent] = useState(false)
    const [hiddenAIText, setHiddenAIText] = useState(false)
    const [hiddenRevolution, setHiddenRevolution] = useState(false)
    const [hiddenCellphone, setCellPhone] = useState(false)
    const [evolutionType, setHiddenRevolutionType] = useState(0);
    const [evolutionText, setEvolutionText] = useState('')
    const [elevantVar, setElevandoVar] = useState(false)
    const [inovandoVar, setInovandoVar] = useState(false)
    const [maximizandoVar, setMaximizandoVar] = useState(false)
    const [superandoVar, setSuperando] = useState(false)

    const [textTypeisMobile, setTypeisMobile] = useState('')


      
    useEffect(() => {
        const topElement = document.getElementById('top'); // ou qualquer ID que você esteja usando para o topo
        topElement.scrollIntoView({ behavior: 'smooth' });

        const subs = scrollY.on('change', (latest) => {
            const previous = scrollY.getPrevious();
            if(latest > 0) {
                setHiddenContent(false)
                setHiddenAIText(false)
            }
            if(latest > 2) {
                setHiddenAIText(false)
            }
            if(latest > 540) {
                setHiddenContent(false)
                setHiddenRevolution(false)
            }
            if(latest > 646) {
            }
            if(latest > 1380) {
                setHiddenAIText(false)
            }
        })
        handleEvoutionType(0)
        handleResize()
        return () => subs()
      }, [scrollY]); // O array de dependências vazio garante que o efeito é executado apenas uma vez durante a montagem e desmontagem do componente
    
      const handleResize = () => {
        setTamanho({ width: window.innerWidth, height: window.innerHeight });

         // Adiciona um ouvinte de evento para a mudança de tamanho da janela
        window.addEventListener('resize', handleResize);
    
        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
      };



      const handleEvoutionType = (type) => {
        switch(type) {
            case 0 : {
               setEvolutionText( `
               Combinamos conhecimento técnico com 
               empatia para entender a fundo seu público-
               alvo, oferecendo soluções que transcendem 
               expectativas. Não se trata apenas de 
               satisfazer clientes, mas de encantá-los.
               `)
               setElevandoVar(true)
               setInovandoVar(false)
               setMaximizandoVar(false)
               setSuperando(false)
               setTypeisMobile(
                <>
                  Elevando o CX a <br /> um Novo Patamar
                </>
              );
            }
            break;
            case 1 : {
                setEvolutionText(`
                Não esperamos o futuro, nós o moldamos. 
                Com estratégias dinâmicas e soluções ágeis, 
                impulsionamos mudanças imediatas e 
                preparadas para o longo prazo.
                `)
                setElevandoVar(false)
                setInovandoVar(true)
                setMaximizandoVar(false)
                setSuperando(false)
                setTypeisMobile(
                    <>
                      Inovação Contínua, <br/>Resultados Imediatos
                    </>
                  );            }
            break;
            case 2 : {
               setEvolutionText( `
               Utilizamos análises precisas para identificar 
               áreas de melhoria e oportunidades,
               resultando em mudanças tangíveis e 
               impactantes, gerando economias significativas 
               para nossos parceiros.
               `)
               setElevandoVar(false)
               setInovandoVar(false)
               setMaximizandoVar(true)
               setSuperando(false)
               setTypeisMobile(
                <>
                 Maximizando Resultados,<br/> Minimizando Custos
                </>
              );
            }
            break;
            case 3 : {
                setEvolutionText(`
                Não prometemos apenas resultados, 
                entregamos superação. Nossas soluções não 
                só geram mudanças, mas também definem 
                novos padrões de excelência.
                `)
                setElevandoVar(false)
                setInovandoVar(false)
                setMaximizandoVar(false)
                setSuperando(true)
                setTypeisMobile(
                    <>
                      Superando<br />Expectativas
                    </>
                  );
            }
            break;
        }
      }

    return (
        <>
            <Content id="top">
            <section
                 class="hero is-light is-fullheight corp-banner">
                <div class="hero-body">
                    <div class='container'>
                    <div class='columns is-vcentered'>
                        <div class='column is-half' style={{marginTop: '80px'}}>
                            <WrapperCorp>
                                    <TitleCorp
                                    variants={{
                                        visible: {
                                            x: 0, 
                                            opacity: 1, 
                                            transition: {
                                                delay: .4
                                            }}
                                    }}
                                    initial={{ x: -50, opacity: 0}}
                                    animate={hiddenContent ? 'hidden' : 'visible'}
                                    transition={{delay: .4}}
                                >CORP</TitleCorp>
                                <SubTitle
                                variants={{
                                    visible: {
                                        x: 0, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .4
                                        }}
                                }}
                                initial={{ x: -50, opacity: 0}}
                                animate={hiddenContent ? 'hidden' : 'visible'}
                                transition={{delay: .4}}
                                   
                                >Prestamos consultoria <span> Multiespecializada</span></SubTitle>
                                <Paragraphy
                                variants={{
                                    visible: {
                                        x: 0, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .4
                                        }}
                                }}
                                initial={{ x: -50, opacity: 0}}
                                animate={hiddenContent ? 'hidden' : 'visible'}
                                transition={{delay: .4}}
                                >Nosso modelo de consultoria especializada vem revolucionando o mundo dos negócios. 
                                </Paragraphy>
                                <Paragraphy
                                variants={{
                                    visible: {
                                        x: 0, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .4
                                        }}
                                }}
                                initial={{ x: -50, opacity: 0}}
                                animate={hiddenContent ? 'hidden' : 'visible'}
                                transition={{delay: .4}}
                                >Utilizamos metodologias comprovadas para impulsionar a excelência com resultados, adaptando-nos constantemente às necessidades em evolução, mantendo sua empresa sempre à frente do mercado. 
                                </Paragraphy>
                            </WrapperCorp>
                        </div>
                        <div className='is-hidden-mobile' class='column'>
                        <ByxImage
                            
                            variants={{
                                visible: {
                                    x: 0, 
                                    opacity: 1, 
                                    transition: {
                                        delay: .4
                                    }}
                            }}
                            initial={{ x: 50, opacity: 0}}
                            animate={hiddenContent ? 'hidden' : 'visible'}
                            transition={{delay: .4}}
                            src={BxyImg} />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <section
                 backGround={BackgroundAI1}
                 class="hero is-light is-fullheight corp-banner-ai is-flex is-justify-center">
                <div class="hero-body">
                    <div class='container is-flex is-flex-direction-column is-align-items-center	'>
                        <div class='column is-half is-vcentered'>
                        <TitleAI
                    variants={{
                        visible: {
                            y: 0, 
                            opacity: 1, 
                            transition: {
                                delay: 1
                            }}
                    }}
                    animate={hiddenAIText ? 'hidden' : 'visible'}
                    transition={{duration: 1}}
                >Inteligência artificial</TitleAI>
                 <AIParagraphy
                variants={{
                    visible: {
                        opacity: 1, 
                        transition: {
                            delay: 1
                        }},
                        hidden: {
                            opacity: 0,
                            transition: {
                                delay: .4
                        }
                    }
                }}
                animate={hiddenAIText ? 'hidden' : 'visible'}
                transition={{duration: 2}}
                >
                Acreditamos no poder transformador da inteligência artificial. Ela possibilita o processamento ágil e eficiente de grandes volumes de dados, resultando em análises rápidas e precisas, independentemente do tamanho ou complexidade dos dados. 

                </AIParagraphy>
                <br/>
                <AIParagraphy
                variants={{
                    visible: {
                        opacity: 1, 
                        transition: {
                            delay: 1
                        }},
                        hidden: {
                            opacity: 0,
                            transition: {
                                delay: .4
                        }
                    }
                }}
                initial={{opacity: 0}}
                animate={hiddenAIText ? 'hidden' : 'visible'}
                transition={{duration: 2}}
                >
                A IA não só analisa dados históricos, mas também utiliza algoritmos avançados para prever tendências futuras, permitindo que as empresas antecipem cenários e identifiquem oportunidades para decisões proativas. 
                </AIParagraphy>
                        </div>
                {/* <People
                variants={{
                    visible: {
                        y: 0, 
                        opacity: 1, 
                        transition: {
                            delay: 1
                        }},
                        hidden: {
                            y: -200,
                            opacity: 0,
                            transition: {
                                delay: 1
                        }
                    }
                }}
                initial={{opacity: 0,  y: -200}}
                animate={hiddenAIText ? 'hidden' : 'visible'}
                transition={{duration: 1.2}}
                src={PeopleImage} /> */}
                        
                </div>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                {tamanho.width >= 650 && tamanho.width < 3800  ? (
                        <Conjunto
                        variants={{
                            visible: {
                                x: 0, 
                                opacity: 1, 
                                transition: {
                                    delay: .4
                                }},
                                hidden: {
                                    x: 0, 
                                    opacity: 1,
                                    transition: {
                                        delay: .4
                                }
                            }
                        }}
                        initial={{ x: 50, opacity: 0}}
                        animate={hiddenContent ? 'hidden' : 'visible'}
                        transition={{delay: .4}}
                        src={ConjuntoImages} />
                    ): null}
                </div>
            </section>
            <TitleEvolution
                    variants={{
                        visible: {
                            y: 0,
                            opacity: 1, 
                            transition: {
                                delay: .4
                            }},
                            hidden: {
                                y: 50,
                                opacity: 0,
                                transition: {
                                    delay: .4
                            }
                        }
                    }}
                    initial={{opacity: 0}}
                    animate={hiddenRevolution ? 'hidden' : 'visible'}
                    transition={{duration: 3}}
                    >A <span>revolução</span> corporativa</TitleEvolution>
            <section 
                 backGround={BackgroundEvolutionImage}
                 class="hero is-light is-fullheight corp-banner-evolution">    
                <div class="hero-body">
                    <div class='container is-fullwidth is-flex is-justify-content-end'>
                        <div class='column is-hidden-mobile'>
                            <Rift src={RiftImage} />
                        </div>
                        <div class='column is-half is-vcentered' style={{width: '45%', marginTop: `${tamanho.width > 768 ? '-320px' : '0px'}`}}>
                        <TitleEvolutionContent>
                            
                        <ContentEvolution
                            variants={{
                                visible: {
                                    y: 0,
                                    opacity: 1, 
                                    transition: {
                                        delay: 1
                                    }},
                                    hidden: {
                                        y: 50,
                                        opacity: 0,
                                        transition: {
                                            delay: .4
                                    }
                                }
                            }}
                            initial={{opacity: 0}}
                            animate={hiddenRevolution ? 'hidden' : 'visible'}
                            transition={{duration: 3}}
                            >
                            <div>
                            Está na hora de ver a experiência do cliente de uma perspectiva totalmente nova. Junte-se à Trabbe corp e descubra como podemos revolucionar seu negócio, do início ao fim.
                            </div> 
                            <div class='columns pt-5 flex-mobile' style={{padding: '0 40px', marginTop: '10px'}}>
                                <div class='column' onClick={() => handleEvoutionType(0)}>
                                    <WrapperImageTypeEvolution>
                                        <EvolutionImageType isActive={elevantVar} src={TypeImageElevando} />
                                        <EvolutionImageTypeText isActive={elevantVar}>Elevando o CX a<br/> um Novo Patamar</EvolutionImageTypeText>
                                    </WrapperImageTypeEvolution>
                                </div>
                                <div class='column' onClick={() => handleEvoutionType(1)}>
                                    <WrapperImageTypeEvolution>
                                        <EvolutionImageType isActive={inovandoVar} src={TypeImageInovando} />
                                        <EvolutionImageTypeText isActive={inovandoVar}>Inovação Contínua, <br/>Resultados Imediatos</EvolutionImageTypeText>
                                    </WrapperImageTypeEvolution>                                
                                </div>
                                <div class='column' onClick={() => handleEvoutionType(2)}>
                                <WrapperImageTypeEvolution>
                                <EvolutionImageType isActive={maximizandoVar} src={TypeImageResultados} />
                                    <EvolutionImageTypeText isActive={maximizandoVar}>Maximizando Resultados,<br/> Minimizando Custos</EvolutionImageTypeText>
                                </WrapperImageTypeEvolution>                          
                                </div>
                                <div class='column' onClick={() => handleEvoutionType(3)}>
                                <WrapperImageTypeEvolution>
                                    <EvolutionImageType isActive={superandoVar} src={TypeImageSuperando} />
                                    <EvolutionImageTypeText isActive={superandoVar}>Superando<br />Expectativas</EvolutionImageTypeText>
                                </WrapperImageTypeEvolution>                      
                                </div>
                            </div>
                            <div><TextTypeMobile>{textTypeisMobile}</TextTypeMobile></div>
                            <EvolutionTextType>{evolutionText}</EvolutionTextType>
                        </ContentEvolution>
                        </TitleEvolutionContent>
                        </div>
                    </div> 
                </div>
            </section>
        </Content>
        </>
    )
}


export default CorpPage