import React, { useEffect, useState } from 'react';
import { BlogBody, BlogHeader, CircleDate, Content, ContentDate, OurBlog, PersonHeader, TitleBlog, TitleContentBlog, TitleHeaderBlog, WrapperDate } from './styles';


import BackgroundImage from '../../assets/images/blog_header/background.png'
import Person from '../../assets/images/our_blog/person.png'

import { SocialNetworks } from '../../components/social_networks';
import Footer from '../../components/footer';

import { getPosts } from '../../services/api'


import { CardBlog, CardContent, CardImage, CardTitle, GridBlog, ImageWrapper, Item, LinkPage, Lista, WrapperBlog, WrapperLista } from './styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';



const BlogContent = ({filtereds}) => {
    const location = useLocation()
    const [filteredNews, setFilteredNews] = useState([]);

    

    useEffect(() => {
        setFilteredNews(filtereds)
    }, [filtereds])


    function goToPost(post) {
        const splited = post.split('.')?.pop().toLowerCase()
        const url = `https://trabbe.${splited}`
        return url
    }

    function removeHTMLtags(html) {
        const data = new DOMParser().parseFromString(html, 'text/html')
        return data.body.textContent || ''
    }

    function limitText(text) {
        const text_limited = text.length > 200 ? `${text.slice(0, 200)}...` : text;
        return text_limited;
      }
      

    return (
         
        <Content>
               
            <WrapperBlog>
                <div class='columns is-multiline'>
                {filteredNews.length > 0 && filteredNews.map(post => {
                            return (
                        (
                            <div class='column is-one-third-desktop is-half-tablet'>
                                <Link style={{width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'center'}} to={goToPost(post.link)}>
                                <CardBlog key={filteredNews ? filteredNews?.id : 0}>
                                    <CardImage>
                                        <ImageWrapper src={post && post["_embedded"]["wp:featuredmedia"][0]["source_url"]} />
                                    </CardImage>
                                    <WrapperDate>
                                        03 de março de 2023
                                        <CircleDate />
                                        5 minutos de leitura
                                    </WrapperDate>
                                    <CardTitle>{post?.title?.rendered}</CardTitle>
                                    <CardContent>{removeHTMLtags(limitText(post?.content?.rendered))}</CardContent>
                                </CardBlog>
                            </Link>
                            </div>
                        )
                    )
                })}              
                 </div>           
            </WrapperBlog>
        </Content>
    )
}



const BlogPage = () => {


    const [posts, setPost] = useState([]);
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true)

    const [navs, setNav] = useState([
        {
            id: 1,
            label: 'Todas'
        },
        {
            id: 15,
            label: 'Home office',
        },
        {
            id: 21,
            label: 'Negociação',
        },
        {
            id: 18,
            label: 'Organização',
        },
        {
            id: 19,
            label: 'Produtividade',
        },
        {
            id: 23,
            label: 'Vendas',
        },
    ])

    const [activeRoutes, setActiveRoutes] = useState([])
    const [filteredNews, setFilteredNews] = useState([]);
    const navigate = useNavigate()
    const [tamanho, setTamanho] = useState({ width: window.innerWidth, height: window.innerHeight });

    const handleClick = (id) => {
        switch(id) {
            case 15: {
                const active = navs.find((find) => find.id === id)
                if (active) {
                    setActiveRoutes([id]);
                }
                const filtered = news.filter(n => n.categories.includes(id))
                setFilteredNews(filtered)
                break
                
                
            }
            case 18: {
                const active = navs.find((find) => find.id === id)
                if (active) {
                    setActiveRoutes([id]);
                }
                const filtered = news.filter(n => n.categories.includes(id))
                setFilteredNews(filtered)
                break
                
            }
            case 19: {
                const active = navs.find((find) => find.id === id)
                if (active) {
                    setActiveRoutes([id]);
                }
                const filtered = news.filter(n => n.categories.includes(id))
                setFilteredNews(filtered)
                break
                
            }
            case 21: {
                const active = navs.find((find) => find.id === id)
                if (active) {
                    setActiveRoutes([id]);
                }
                const filtered = news.filter(n => n.categories.includes(id))
                setFilteredNews(filtered)
                break
                
            }
            case 23: {
                const active = navs.find((find) => find.id === id)
                if (active) {
                    setActiveRoutes([id]);
                }
                const filtered = news.filter(n => n.categories.includes(id))
                setFilteredNews(filtered)
                break
                
            }
            default: {
                const active = navs.find((find) => find.id === id)
                if (active) {
                    setActiveRoutes([id]);
                }
                setFilteredNews(news)
                break
                
            }
        }
        

       
    }


    useEffect(() => {
        window.location.href = 'http://trabbe.com/blog/';
        
    }, [])

    const handleResize = () => {
        setTamanho({ width: window.innerWidth, height: window.innerHeight });

         // Adiciona um ouvinte de evento para a mudança de tamanho da janela
        window.addEventListener('resize', handleResize);
    
        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
      };

    return (
        <>
         <Content id='top'>
            <section
                 class="hero is-light is-fullheight" style={{backgroundColor: '#fff', overflow: 'hidden'}}>
                <div class="hero-body">
                    <div class='container is-flex is-justify-content-center	is-align-items-center'>
                        <CircularProgress color="inherit" />
                    </div>
                </div>
            </section>
        </Content>
        </>
    )
}


export default BlogPage