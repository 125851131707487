import { color, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import BackgroundBarra from '../../assets/images/home_header/background.svg'





export const ConteudoDigital = styled(motion.section)`
        background: url(${BackgroundBarra});
        background-size: cover;
        background-position: -1100px 50px ;
`;


export const Merc = styled(motion.h1)`
    font-size: 40px !important;
    font-weight: bold;
    color: #484848;
     span {
        background: transparent linear-gradient(108deg, #DC6C61 0%, #381679 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        font-weight: bold;
        color: transparent;
     }
    
    // Add more media queries as needed for other screen sizes
`;

export const RotaText = styled(motion.h2)`
    margin-top: 9px;
    padding: 10px 0;
    color: ${colors.blackgray};
    font-weight: 400;
    display: flex;
    flex-direction: column;
    font-size: 40px !important;
    line-height: 1.2;

    span {
        font-weight: bold;
    }

`;

export const PlataformaText = styled(motion.p)`
    margin-top: 16px;
    margin-bottom: 40px;
    font-size: 24px !important;
    font-weight: normal;
    max-width: 477px;


`;

export const WrapperDownload = styled.div`
    display: flex;
    align-items: center;
`;

export const DownloadGoogle = styled.img`
    width: 210px;

`;

export const DownloadApple = styled.img`
    width: 216px;
    margin-left: 10px;
    font-size: bold;
    
`;

export const AppImage = styled(motion.img)`
`;

export const LinkPage = styled(Link)`
    text-decoration: none;
    cursor: pointer;
`;

export const Comunity = styled.section`
  background: url(${props => props.backGround}) no-repeat;
  background-color: #F7DFF2;
  background-size: cover;
  position: relative;
  

`;

export const TrabbeImage = styled.img`

`;

export const ButterFly = styled(motion.img)`
    position: absolute;
    right: 20px;
    top: -90px;
`;

export const CellPhone = styled(motion.img)`
    position: absolute;
    z-index: 400;
`;

export const Butterflies = styled(motion.img)`
    position: absolute ;
    z-index: 300;
    bottom: 0px;
`;

export const TrabbeComunityContent = styled.p`
    font-size: 20px;
    max-width: 440px;
    margin-bottom: 20px;
    color: #707070;

`;

export const TrabbeVideo = styled.div`
    position: relative;
    text-align: center;
   
    width: 100%;/* proporção 16:9, ajuste conforme necessário */
    aspect-ratio: 16 / 9;


    .column-width {
        width: 100%;
    }
    

`;


export const RoseLeafRight = styled.img`
    position: absolute;
    right: 60px;
    bottom: -150px;
    z-index: 4000;

`

export const RoseLeaf = styled.img`
    position: absolute;
    left: 20px;
    top: -160px;
    z-index: 4000;

`;

export const TrabbeTitle = styled.h1`
    font-size: 40px;
    color: ${colors.blackgray};
    margin-bottom: 41px;
    font-weight: bold;

    span {
        background: transparent linear-gradient(108deg, #DC6C61 20%, #381679 80%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        color: transparent;
    }


`;

export const YoutubePlayer = styled(YouTube)`

`;


export const Content = styled(motion.div)`
    width: 100%;
    position: relative;

    @media screen and (min-width: 250px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 32px !important;
        }

        ${RotaText} {
            font-size: 32px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            font-size: 20px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 36px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        ${TrabbeImage} {
            width: 150px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${ButterFly} {
            top: 700px;
        }

        ${TrabbeTitle} {
            font-size: 26px;
        }


        ${CellPhone} {
            position: relative;
            transform: scale(2) !important;
            right: -20px;
            bottom: 0px;
        }

        ${TrabbeComunityContent} {
            max-width: 230px !important;
            font-size: 20px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 26px;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 220px;
                height: 140px !important;
                border: none; 
            }
            
        }

    }

    @media screen and (min-width: 375px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 32px !important;
        }

        ${RotaText} {
            font-size: 32px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            font-size: 20px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 36px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        ${ButterFly} {
            top: 500px;
            left: 50px;
        }

        ${TrabbeImage} {
            width: 250px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${TrabbeTitle} {
            font-size: 26px;
        }

       
        ${CellPhone} {
            position: relative;
            transform: scale(1.5) !important;
            right: -20px;
            bottom: 0px;
        }
        

        ${TrabbeComunityContent} {
            max-width: 300px !important;
            font-size: 20px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 26px;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 250px;
                height: 140px !important;
                border: none; 
            }
            
        }

    }

    @media screen and (min-width: 400px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 32px !important;
        }

        ${RotaText} {
            font-size: 32px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            font-size: 20px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 36px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        
        ${ButterFly} {
            top: 500px;
        }

        ${TrabbeImage} {
            width: 350px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${CellPhone} {
            position: relative;
            transform: scale(1.5) !important;
            right: -20px;
            bottom: -20px;
        }

        ${TrabbeComunityContent} {
            max-width: 400px !important;
            font-size: 20px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 30px;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 300px;
                height: 220px !important;
                border: none; 
            }
            
        }

    }

    @media screen and (min-width: 425px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 32px !important;
        }

        ${RotaText} {
            font-size: 32px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            font-size: 20px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 36px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        ${ButterFly} {
            top: 400px;
        }

        ${TrabbeImage} {
            width: 350px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${CellPhone} {
            position: relative;
            transform: scale(1.5) !important;
            right: -20px;
            bottom: 0px;
        }

        ${TrabbeComunityContent} {
            max-width: 400px !important;
            font-size: 20px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 30px;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 350px;
                height: 250px !important;
                border: none; 
            }
            
        }

    }

    @media screen and (min-width: 768px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 36px !important;
        }

        ${RotaText} {
            font-size: 36px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            font-size: 22px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 36px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        
        ${ButterFly} {
            top: 300px;
            left: 150px;
        }

        ${TrabbeImage} {
            width: 450px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${CellPhone} {
            position: relative;
            transform: scale(1.5) !important;
            right: -100px;
            bottom: 20px;
        }


        ${TrabbeComunityContent} {
            max-width: 596px !important;
            font-size: 20px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 30px;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 680px;
                height: 450px !important;
                border: none; 
            }
            
        }

    }

    @media screen and (min-width: 1024px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 36px !important;
        }

        ${RotaText} {
            font-size: 36px !important;
            line-height: 2.6rem;
        }

        ${PlataformaText} {
            font-size: 22px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 36px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        ${ButterFly} {
            top: -50px;
            left: 450px;
        }

        ${TrabbeImage} {
            width: 450px !important;
            left: 40px !important;
            top: 70px !important;
        }
        ${CellPhone} {
            position: relative;
            transform: scale(2) !important;
            right: -20px;
            bottom: -100px;
        }


        ${TrabbeComunityContent} {
            max-width: 596px !important;
            font-size: 20px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 32px;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 880px;
                height: 550px !important;
                border: none; 
            }
            
        }

    }

    @media screen and (min-width: 1440px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 40px !important;
        }

        ${RotaText} {
            font-size: 40px !important;
            line-height: 2.2rem;
        }

        ${PlataformaText} {
            font-size: 24px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 40px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        ${ButterFly} {
            top: -50px;
            left: 700px;
        }

        ${TrabbeImage} {
            width: 512px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${CellPhone} {
            position: relative;
            transform: scale(1.7) !important;
            right: 20px;
            bottom: -80px;
        }

        ${TrabbeComunityContent} {
            width: 446px !important;
            font-size: 22px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 40px;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 1200px;
                height: 650px !important;
                border: none; 
            }
            
        }

    }
    

    @media screen and (min-width: 1980px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 40px !important;
        }

        ${RotaText} {
            font-size: 40px !important;
            line-height: 2.2rem;
        }

        ${PlataformaText} {
            font-size: 24px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 40px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        ${TrabbeImage} {
            width: 450px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${Butterflies} {
            display: none;
        }

        ${CellPhone} {
            position: relative;
            transform: scale(2) !important;
            right: -20px;
            bottom: -50px;
        }

        ${TrabbeComunityContent} {
            max-width: 556px !important;
            font-size: 22px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 40px;
            color: #000000;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 1200px;
                height: 650px !important;
                border: none; 
            }
            
        }

    }

    @media screen and (min-width: 2560px){
        ${AppImage} {
            display: block;
        }

        ${ConteudoDigital} {
            justify-content: center;
        }

        ${Merc} {
            font-size: 40px !important;
        }

        ${RotaText} {
            font-size: 40px !important;
            line-height: 2.2rem;
        }

        ${PlataformaText} {
            font-size: 24px;
            color: #707070;
        }

        ${DownloadApple} {
         margin: 0 !important;
        }

        ${WrapperDownload} {
            flex-direction: row !important;
            margin-top: 40px;
        }

        .apple_download {
            margin-top: 0px !important;
            margin-left: 10px;
        }

        ${RoseLeafRight} {
            display: block;
        }

        ${RoseLeaf} {
            display: block;
        }

        ${Comunity} {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        ${TrabbeImage} {
            width: 450px !important;
            left: 40px !important;
            top: 70px !important;
        }

        ${CellPhone} {
            position: relative;
            transform: scale(2.5) !important;
            right: -500px;
            bottom: -570px;
        }

        ${TrabbeComunityContent} {
            font-size: 22px;
            margin-top: 40px;
        }

        ${TrabbeTitle} {
            font-size: 40px;
            color: #000000;
        }

        ${TrabbeVideo} {
            width: 100%;

            
            iframe {
                width: 1200px;
                height: 650px !important;
                border: none; 
            }
            
        }

    }

    
`;


 