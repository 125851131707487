import styled from 'styled-components';
import {Swiper} from 'swiper/react';



export const WrapperIphone = styled.div`
    position: absolute;
    z-index: 9998;
    top: 30px !important;
    left: 535px;

    
    @media screen and (min-width: 0px) and (max-width: 374px) {
      top: 32px !important;
      left: 540px !important;
    }

    @media screen and (min-width: 0px) and (max-width: 2560px) {
      top: 26px !important;
      left: 610px !important;
    }
`;

export const IphoneImage = styled.img`
    width: 382px;

    @media screen and (min-width: 0px) and (max-width: 374px) {
      width: 395px;
    }

    @media screen and (min-width: 0px) and (max-width: 2560px) {
      width: 390px;
    }

`;

export const WrapperImageSwiper = styled.div`
    width: 258px !important;

   

`

export const ImageSwiper = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;


export const SwipperCustom = styled(Swiper)`
    height: 36rem;
    padding: 2rem 0;
    position: relative;
  
  &.swiper-slide {
    width: 7rem;
    height: 42rem;
    position: relative;
  }
  
  &.swiper-slide img {
    height: 42rem;
    border-radius: 2rem;
    object-fit: cover;
  }
  
  &.swiper-slide-shadow-left,
  &.swiper-slide-shadow-right {
    display: none;
  }

`;


export const SwiperPagination = styled.div`
    position: relative;
    width: 50rem !important;
    bottom: 1rem;
  
  &.swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  &.swiper-pagination-bullet-active {
    background: red;
  }


`



export const SlideController = styled.div`
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  
  &.slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
  }
  
  &.slider-arrow::after {
    content: '';
  }

`;


export const WrapperCaroussel = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 30px;
    
`;


export const WrapperSwiper = styled.div`
    width: 1490px;
    margin-bottom: 20px;
    margin-left: 115px;
`;




