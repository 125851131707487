import { SocialNetworks } from '../../components/social_networks';
import React, { useEffect, useState } from 'react';
import { BigData, BigDataImage, BlueBrain, BlueBrainImg, BrainTrabbe, Content, ContentBigData, ContentBlueBrain, Dados, ListaBigData, Paragraphy, SubTitle, TitleBigData, TitleBlueBrain, TitleDados, WrapperBigData, WrapperBlueBrain, WrapperDados } from './styles';

import DadosImage from '../../assets/images/dados/background.svg'
import BrainImage from '../../assets/images/dados/brain.png'
import BlueBrainImageNoBrain from '../../assets/images/blue_brain/brainbackground.svg'
import BlueBrainImageBrain from '../../assets/images/blue_brain/brain.png'
import BlueBrainImageConjunto from '../../assets/images/blue_brain/background.png'


import DadoBigData from '../../assets/images/big_data/dadobigdata.png'

import { useScroll } from 'framer-motion';



const DadosPage = () => {
    const {scrollY} = useScroll()

    const [dados, setDados] = useState(true)
    const [brain, setBrain] = useState(true)
    const [ai, setAi] = useState(false)
    const [tamanho, setTamanho] = useState({ width: window.innerWidth, height: window.innerHeight });

      
    useEffect(() => {
        const topElement = document.getElementById('top'); // ou qualquer ID que você esteja usando para o topo
        topElement.scrollIntoView({ behavior: 'smooth' });

        if(window.location.pathname === '/merc') {
            const topElement = document.getElementById('top'); // ou qualquer ID que você esteja usando para o topo
            topElement.scrollIntoView({ behavior: 'smooth' });
        }
        
        setDados(true)
        const subs = scrollY.on('change', (latest) => {
            const previous = scrollY.getPrevious();
            if(latest > 0) {
                setDados(true)
            }
            if(latest > 92) {
                setBrain(false)
                
            }
            if(latest > 540) {
                setDados(true)
                
            }
            if(latest > 943) {
                setAi(true)
            }
            if(latest > 819) {
            }
        })
        handleResize()
        return () => subs()
      }, [scrollY]);

      const handleResize = () => {
        setTamanho({ width: window.innerWidth, height: window.innerHeight });

         // Adiciona um ouvinte de evento para a mudança de tamanho da janela
        window.addEventListener('resize', handleResize);
    
        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
      };
    return (
        <>
            <Content id='top'>
            <section backGround={DadosImage} 
                    initial={{ opacity: 1 }}
                    viewport={{ once: true }}
                    class="hero is-light is-fullheight dados-banner-dados"
                    >
                <div class="hero-body">
                    <div class='container'>
                    <div class='columns is-vcentered is-justify-content-space-between'>
                        <div class='column is-half' style={{marginTop: '80px'}}>
                        <WrapperDados>
                        <TitleDados
                            variants={{
                                visible: {
                                    x: 0, 
                                    opacity: 1, 
                                    transition: {
                                        delay: .4
                                    }}
                            }}
                            initial={{ x: -50, opacity: 0}}
                            animate={dados ? 'visible' : 'hidden'}
                            transition={{delay: .4}}
                        >DADOS</TitleDados>
                        <SubTitle
                            variants={{
                                visible: {
                                    x: 0, 
                                    opacity: 1, 
                                    transition: {
                                        delay: .4
                                    }}
                            }}
                            initial={{ x: -50, opacity: 0}}
                            animate={dados ? 'visible' : 'hidden'}
                            transition={{delay: .4}}
                        >Informações tratadas com <span>Inteligência e eficiência</span></SubTitle>
                        <Paragraphy
                            variants={{
                                visible: {
                                    x: 0, 
                                    opacity: 1, 
                                    transition: {
                                        delay: .4
                                    }}
                            }}
                            initial={{ x: -50, opacity: 0}}
                            animate={dados ? 'visible' : 'hidden'}
                            transition={{delay: .4}}
                        >Dados são um dos ativos mais valiosos para o sucesso de uma empresa. Na Trabbe Dados, oferecemos gestão completa, desde a coleta e tratamento até o uso de inteligência artificial para criar pontuações e insights estratégicos seguindo todas as normas de LGPD.</Paragraphy>
                    </WrapperDados>
                        </div>
                        <div class='column column-2'>
                        <BrainTrabbe
                            variants={{
                                visible: {
                                    x: 0, 
                                    opacity: 1, 
                                    transition: {
                                        delay: .4
                                    }}
                            }}
                            initial={{ x: 50, opacity: 0}}
                            animate={dados ? 'visible' : 'hidden'}
                            transition={{delay: .4}}
                    
                    src={BrainImage} />
                        </div>
                    </div>
                    </div>
                </div>
                </section>
               <section 
                    backGround={BlueBrainImageNoBrain} 
                    initial={{ opacity: 1 }}
                    viewport={{ once: true }}
                    class="hero is-light is-fullheight dados-banner-dados-ai"
                    >
                <div class="hero-body">
                    <div class='container'>
                    <div class='columns is-vcentered is-justify-content-space-between'>
                        <div class='column is-half'>
                        
                                <BlueBrainImg 
                                variants={{
                                    visible: {
                                        y: -60, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .4,
                                            duration: 1
                                        }}
                                }}
                                animate={brain ? 'hidden' : 'visible'}
                                initial={{ opacity: 0, y: -100}}
                                transition={{duration: 1.2 }} 
                                src={BlueBrainImageBrain} />
                        </div>
                        <div class='column column-2'>
                        <WrapperBlueBrain>               
                        <TitleBlueBrain
                            variants={{
                                visible: {
                                    y: -100, 
                                    opacity: 1, 
                                    transition: {
                                        delay: .2
                                    }}
                            }}
                            animate={brain ? 'hidden' : 'visible'}
                            transition={{duration: .5 }} 
                        >Por que terceirizar as tratativas de dados da minha empresa?​</TitleBlueBrain>
                        <ContentBlueBrain
                            variants={{
                                visible: {
                                    y: -100, 
                                    opacity: 1, 
                                    transition: {
                                        delay: .5
                                    }}
                            }}
                            animate={brain ? 'hidden' : 'visible'}
                            transition={{duration: .5 }}
                        >
                            Trabbe Dados é sua parceira ideal para ​

                            otimizar o tratamento dos dados ​

                            empresariais. Nossa expertise especializada é ​

                            um diferencial claro: oferecemos serviços de ​

                            alta qualidade, garantindo precisão e ​

                            eficiência em cada etapa do processo.​

                            ​<br />​<br />

                            Sua empresa se beneficia imediatamente da ​

                            economia de custos além de ganhar tempo ​

                            para focar plenamente no seu core business. ​

                            Isso impulsiona a produtividade e permite ​

                            direcionar esforços para áreas estratégicas, ​

                            enquanto nós cuidamos do tratamento ​

                            detalhado dos dados para você.​ 
                        </ContentBlueBrain>
                </WrapperBlueBrain>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
                <section backGround={BigDataImage}
                 class="hero is-light is-fullheight dados-banner-dados-bigdata">
                    
                <div class="hero-body">
                    <div class='container'>
                        <div class='columns is-vcentered is-justify-content-space-between'>
                        <div class='column is-half is-vcentered is-flex is-justify-content-space-between'>
                        <WrapperBigData>
                            <ContentBigData
                                variants={{
                                    visible: {
                                        x: 0, 
                                        opacity: 1, 
                                        transition: {
                                            delay: .9
                                        }}
                                }}
                                animate={brain ? 'hidden' : 'visible'}
                                transition={{duration: 4 }}
                            
                            >
                                <TitleBigData>Análise de Dados</TitleBigData>
                                Imagine ter todas as informações necessárias de forma prática, na palma da sua mão? Além de garantir segurança da informação, aderindo a todas as normas de Compliance, a Trabbe Dados também oferece: 
                                    <br/><br/>
                                    <ListaBigData>
                                    <li>Serviço de coleta de tratamento de dados massivos. </li>
                                    <br/>
                                    <li>Inteligência Artificial para avaliação estatística e aprimoramentos estratégicos.</li> 
                                    <br/>
                                    <li>Dashboards personalizados para gerenciamento de indicadores.</li> 
                                    <br/>
                                    <li>Consultoria de Dados, com apoio em definição de KPI’s, análises e diagnósticos precisos.</li>
                                    <br/><br/>
                                    </ListaBigData> 
                            </ContentBigData>
                    </WrapperBigData>
                        </div>
                        <div className='column'>
                            <BigDataImage src={DadoBigData} />
                        </div>
                        </div>
                        
                    </div> 
                </div>
                </section>
            </Content>
        </>
    )
}


export default DadosPage