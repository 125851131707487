import React, { Component } from 'react';

import Linkedin from '../../assets/icons/linkedin.svg';
import Youtube from '../../assets/icons/youtube.svg';
import Facebook from '../../assets/icons/facebook.svg';
import Instagram from '../../assets/icons/instagram.svg';
import Tiktok from '../../assets/icons/tiktok.svg';

import {
    WrapperSocialNetwork,
    ImageLink,
    ImageSocial
} from './styles'


export const SocialNetworks = () => {
    return (
        <WrapperSocialNetwork>
            <ImageLink to='https://www.linkedin.com/company/trabbebr' target='_blank'>
                <ImageSocial src={Linkedin} />
            </ImageLink>
            <ImageLink to='http://facebook.com/trabbebr' target='_blank'>
                <ImageSocial src={Facebook} />
            </ImageLink>
            <ImageLink to='https://www.instagram.com/trabbebr/' target='_blank'>
                <ImageSocial src={Instagram} />
            </ImageLink>
            <ImageLink to='https://www.youtube.com/@trabbebr' target='_blank'>
                <ImageSocial src={Youtube} />
            </ImageLink>
            <ImageLink to='https://www.tiktok.com/@trabbebr' target='_blank'>
                <ImageSocial src={Tiktok} />
            </ImageLink>
        </WrapperSocialNetwork>
    )
}