import React, { createContext, useContext, useState } from 'react';

// Criando o contexto
const EventContext = createContext();

// Hook customizado para fornecer acesso ao contexto
export const useEventContext = () => {
  const context = useContext(EventContext);
  if (!context) {
    throw new Error('useEventContext deve ser usado dentro de um provedor EventProvider');
  }
  return context;
};

// Componente Provedor que detém o estado
export const EventProvider = ({ children }) => {
  const [hideHeader, setHideHeader] = useState(false);

  const handleNavbar = (data) => {
    setHideHeader(data);
  };

  return (
    <EventContext.Provider value={{ hideHeader, handleNavbar, setHideHeader }}>
      {children}
    </EventContext.Provider>
  );
};