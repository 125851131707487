import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

 /* Adiciona a animação de subir e descer continuamente */
 const  moveUpDown = keyframes`
 0%, 100% {
  transform: translateY(0);
}
50% {
  transform: translateY(-20px);
}
`;


export const WrapperSocialNetwork = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
    position: fixed;
    z-index: 9999;
    bottom: 20px;
    left: 20px;

    @media only screen and (min-width: 1281px) and (min-height: 721px) {
        // Default size for larger screens
    }

    @media only screen and (max-width: 1280px) and (max-height: 720px) {
         // Adjusted size for smaller screens
    }

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        transform: scale(1.2,1.2);
        left: 40px;
    }

`;

export const ImageLink = styled(Link)`
    transition: transform 200ms ease;

    &:hover {
        transform: translateY(-10px);
        animation: ${moveUpDown} 1s infinite;
    }
`;

export const ImageSocial = styled.img`
    width: 120%;
    height: 100%;
`;

 

 
  

