import styled from "styled-components";
import { colors } from "../../utils/colors";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export const ButtonPortal = styled.button`
    border-radius: 24px;
    opacity: 1;
    width: 50%;
    height: 45px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    border: none !important;
    background-color: ${colors.purple};
    border-color: none;
    font-size: 20px;
    font-weight: normal;
    font: normal normal normal 20px/30px Poppins;
    letter-spacing: 0.09rem;
   
    cursor: pointer;

    @media only screen and (min-width: 1024px){
        margin-left: 80px;
        width: 226px;
    }
    
`;



export const WrapperNavBar = styled.div`
    


`;

export const Header = styled.nav`
    height: 90px;
    background-color: ${colors.white};
    box-shadow: 0px 0px 8px #00000029;

    button {
        border-color: none !important;
    }


    
   
`;

export const LinkPage = styled(Link)`
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => (props.isActive ? `${colors.main}` : `${colors.blackgray}`)} !important;
    display: flex;
    align-items: center;
    background-color: transparent !important;
    font: normal normal normal 20px/30px Poppins;
    outline: none !important;
    font-size: 20px;
    .navbar-item img {
        max-height: 0px;
    }

    &.center-button {
        justify-content: center;
    }
`;


export const Logo = styled.img`
    width: 169px;
    height: 43px;

    @media only screen and (min-width: 1281px) and (min-height: 721px) {
        width: 169px; // Default size for larger screens
    }

    @media only screen and (max-width: 1280px) and (max-height: 720px) {
        width: 169px // Adjusted size for smaller screens
    }

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 160px // Adjusted size for larger screens
    }
`;

export const RightSide = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

`;


