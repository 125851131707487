import styled from 'styled-components';
import FooterBackground from './footer.png';
import { colors } from 'utils/colors';
import { Link } from 'react-router-dom';




export const Listitens = styled.ul`
    display: flex;
    width: 100%;
    justify-content: space-around;
`;

export const List = styled.li`
    display: flex;
    flex-direction: column;
    
`;

export const TrabbeLogo = styled.img`
    width: 210px;

    @media only screen and (min-width: 1281px) and (min-height: 721px) {
        font-size: 210px; // Default size for larger screens
    }

    @media only screen and (max-width: 1280px) and (max-height: 720px) {
        font-size: 210px;
    }

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 240px;// Adjusted size for larger screens
    }

`;


export const FooterTitle = styled.h2`
    font-size: 20px;
    color: ${colors.main};
    font-weight: 500;
    margin-bottom: 20px;

    @media only screen and (min-width: 1281px) and (min-height: 721px) {
        font-size: 20px; // Default size for larger screens
    }

    @media only screen and (max-width: 1280px) and (max-height: 720px) {
        font-size: 20px;
    }

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 25px;// Adjusted size for larger screens
    }

`;

export const LinkPage = styled(Link)`
    text-decoration: none;
    color: ${(props) => (props.isActive ? `#5A248A` : `${colors.blackgray}`)} !important;
    font-size: 16px;
    color: #000;
    margin-top: 20px;
    cursor: pointer;
    font: normal normal normal 16px/12px Roboto;

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 20px;// Adjusted size for larger screens
    }
`;

export const TrabbeWorks = styled.span`
    font-size: 16px;
    color: #000;
    max-width: 180px;

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 20px;// Adjusted size for larger screens
    }
`;


export const TrabberMail = styled.div`
    font-size: 16px;
    color: #000;
    max-width: 210px;
    font-weight: bold;
    margin-top: 20px;
    cursor: pointer;

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 20px;// Adjusted size for larger screens
    }
    

    span {
        color: ${colors.main};
        font-weight: 500;
    }
`;


export const TrabbeWhatsApp = styled(Link)`
    display: flex;
    align-items: center;
    color: ${colors.main};
    cursor: pointer;
    margin-top: 10px;
`;

export const TrabbeWhatsAppIcon = styled.img`
    width: 30px;
    cursor: pointer;
`;

export const TrabbeWhatsAppLabel = styled.label`
    margin-left: 5px;
    cursor: pointer;
`;

export const TrabberPhone = styled.div`
    font-size: 16px;
    color: #000;
    max-width: 210px;
    font-weight: bold;
    margin-top: 20px;

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 20px;// Adjusted size for larger screens
    }

    span {
        color: #C7C7C7;
        font-weight: 500;
    }
`

export const NewsLetter = styled.div`
    margin-top: 80px;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .buttonrd {
        width: 100% !important;
        max-width: 640px;

        form {
            display: flex;
            flex-direction: column;
            

            .bricks-form__fieldset {
                flex-direction: column !important;
                width: 100%;
                

                .bricks-form__field {
                    width: 100% !important;

                    input {
                        border: none !important;
                        text-shadow: none !important;
                        box-shadow: none !important;
                        border-radius: 4px !important;
                        padding: 25px 10px !important;
                        background-color: transparent !important;
                        font-style: italic !important;
                        outline: none !important;

                    }
                   
                }

                .bricks-form__submit {
                    button {
                        width: 162px !important;
                    }
                }
            }

                button {
                    width: 162px !important;
                    background-color: ${colors.main} !important;
                    border-radius: 4px !important;
                    color: ${colors.white} !important;
                    font-weight: normal !important;
                    height: 40px !important;
                    border: none !important;
                    font-size: 16px !important;
                    cursor: pointer !important;
                }
        }
    }
`;

export const NewsLetterTitle = styled.div`
    color: ${colors.blackgray};
    font-weight: bold;
    font-size: 16px;
    max-width: 640px;
    margin-bottom: -8px;

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 20px;// Adjusted size for larger screens
    }
    
`

export const WrapperNewsInput = styled.div`
    width: 100%;
    border: 1px solid ${colors.main};
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 640px;
    margin-top: 40px;
    border-radius: 8px;
`;

export const Input = styled.input.attrs({
    placeholderTextColor: '#C7C7C7'
})`
    border: none;
    appearance: none;
    color: ${colors.blackgray};
    outline: none;
    width: 100%;
    font-size: 16px;
    font-style: italic;

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 18px;// Adjusted size for larger screens
    }
`;

export const WrapperButton = styled.button`
    width: 162px;
    background-color: ${colors.main};
    border-radius: 4px;
    color: ${colors.white};
    font-weight: 300;
    height: 40px;
    border: none;
    font-size: 16px;
    cursor: pointer;

    @media only screen and (max-width: 1920px) and (max-height: 1080px) {
        font-size: 18px;
    }
`;

export const FooterAddress = styled.div`
    margin-top: 80px;
    color: #C7C7C7;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-top-width: 1px;
    border-top-color: #C7C7C7;
    border-top-style: solid;
    padding: 40px;

    @media only screen and (min-width: 1281px) and (min-height: 1081px) {
        font-size: 18px;// Adjusted size for larger screens
    }

    span {
        max-width: 900px;
        
    }

`

export const WrapperFooter = styled.div`
    height: 100%;
    width: 100%;
    background-image: url(${FooterBackground});
    background-size: contain;
    background-repeat: no-repeat;
    padding-top: 83px;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;

    @media only screen and (max-width: 375px) and (max-height: 667px) {
        ${Listitens} {
            flex-direction: column;
            align-items: center;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 100%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 10px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 60px !important;
        }
    }

    @media only screen and (min-width: 414px)  and (max-height: 896px) {
        ${Listitens} {
            flex-direction: column;
            align-items: center;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 100%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 10px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 60px !important;
        }
    }

    @media only screen and (min-width: 390px)  and (max-height: 844px) {
        ${Listitens} {
            flex-direction: column;
            align-items: center;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 100%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 10px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 60px !important;
        }
    }

    @media only screen and (max-width: 412px) and (max-height: 915px) {
        ${Listitens} {
            flex-direction: column;
            align-items: center;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 100%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 10px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 60px !important;
        }
    }
    
    @media only screen and (max-width: 430px) and (max-height: 932px) {
        ${Listitens} {
            flex-direction: column;
            align-items: center;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 100%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 10px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 60px !important;
        }
    }

    // Tablets


    @media only screen and (max-width: 768px) and (max-height: 1024px) {
        .logo {
            display: none;
        }
        ${Listitens} {
            align-items: flex-start;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 90%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 20px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 162px;
            margin-top: 10px;
            height: 60px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 60px !important;
        }
    }

    @media only screen and (min-width: 820px) and (max-width: 1380px) {
        .logo {
            display: block;
        }
        ${Listitens} {
            align-items: flex-start;
            flex-direction: row;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 90%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 20px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
            height: 60px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 40px !important;
        }
    }

    @media only screen and (min-width: 1381px) and (max-width: 2205px) {
        .logo {
            display: block;
        }
        ${Listitens} {
            align-items: flex-start;
            flex-direction: row;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 90%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 20px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
            height: 60px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 40px !important;
        }
    }

    @media only screen and (min-width: 2081px) and (max-width: 2300px) {
        .logo {
            display: block;
        }
        ${Listitens} {
            align-items: flex-start;
            flex-direction: row;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 90%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 20px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
            height: 60px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 40px !important;
        }
    }

    @media only screen and (min-width: 2301px) and (max-width: 2560px) {
        .logo {
            display: block;
        }
        ${Listitens} {
            align-items: flex-start;
            flex-direction: row;
        }

        ${List} {
            text-align: left !important;
            margin: 0;
            margin-top: 40px;
            width: 90%;
        }
        
        ${WrapperNewsInput} {
            flex-direction: column;
            border: none;
        }

        ${Input} {
            border: 1px solid ${colors.main};
            padding: 20px 24px;
            border-radius: 8px;
        }

        ${WrapperButton} {
            width: 100%;
            margin-top: 10px;
            height: 60px;
        }

        .logo {
            justify-content: center;
            align-items: center;
            margin-top: 40px !important;
        }
    }


`;




