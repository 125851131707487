import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow, Autoplay } from 'swiper/modules';


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css/navigation'



import Cel1 from '../../assets/images/cel1.png'
import Cel2 from '../../assets/images/cel2.png'
import Cel3 from '../../assets/images/cel3.png'
import Cel4 from '../../assets/images/cel4.png'
import Cel5 from '../../assets/images/cel5.png' 


import Iphone from '../../assets/images/iphone.svg'

import {
  ImageSwiper,
  IphoneImage,
  SwipperCustom, 
  WrapperCaroussel, 
  WrapperImageSwiper, 
  WrapperIphone, 
  WrapperSwiper
} from './styles'


const Carousel = () => {
    return (
        <WrapperCaroussel>
          <WrapperIphone>
            <IphoneImage src={Iphone} />
          </WrapperIphone>
          <WrapperSwiper>
          <SwipperCustom
        modules={[Autoplay,EffectCoverflow, Pagination, Navigation]}
          effect={'coverflow'}
          centeredSlides={true}
          slidesPerView={4}
          grabCursor={true}
          loop={true}
          pagination={{
            el: '.swiper-pagination', clickable: true
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true
          }}
          coverflowEffect={{
            rotate: 9,
            stretch: 0,
            depth: 100,
            modifier: 1.5
          }}
          autoplay={{ delay: 1200, disableOnInteraction: false }}
        >
          <SwiperSlide>
            <WrapperImageSwiper>
              <ImageSwiper src={Cel1}/>
            </WrapperImageSwiper>
          </SwiperSlide>
          <SwiperSlide>
            <WrapperImageSwiper>
              <ImageSwiper src={Cel2}/>
            </WrapperImageSwiper>
          </SwiperSlide>
          <SwiperSlide>
            <WrapperImageSwiper>
              <ImageSwiper src={Cel3}/>
            </WrapperImageSwiper>
          </SwiperSlide>
          <SwiperSlide>
            <WrapperImageSwiper>
              <ImageSwiper src={Cel4}/>
            </WrapperImageSwiper>
          </SwiperSlide>
          <SwiperSlide>
            <WrapperImageSwiper>
              <ImageSwiper src={Cel5}/>
            </WrapperImageSwiper>
          </SwiperSlide>
        </SwipperCustom>
          </WrapperSwiper>
          <div className='swiper-pagination'></div>
        </WrapperCaroussel>
      );
};

export default Carousel;
