import React, { useEffect } from 'react'


import {
    Content,
    Header, Paragraphy, WrapperPage
} from './styles'
import Footer from '../../components/footer';



export const TermosPoliticies = () => {

    useEffect(() => {
        const topElement = document.getElementById('top'); // ou qualquer ID que você esteja usando para o topo
        topElement.scrollIntoView({ behavior: 'smooth' });
    }, [])

    let texto = `
    A gente sabe que muitas vezes dá vontade de pular a leitura de documentos como este, mas ele é importante para garantir que você saiba o que está contratando/ofertando e sua relação com a trabbe.

Antes de começar, seguem algumas definições que irão facilitar a leitura e entendimento do documento:

trabbe - Empresa de serviços via plataforma digital - website e aplicativo - destinada a intermediar o relacionamento, conectando prestadores de serviços independentes (usuários) e contratantes (anunciantes).

trabber usuário - prestador de serviço independente cadastrado na trabbe.

anunciante– contratante da prestação de serviços de um usuário por intermédio da trabbe e suas
Plataformas digitais - website e aplicativo trabbe.

Serviços de Intermediação e Utilização da Plataforma - Serviço provido pela trabbe para anunciantes e usuários, por meio de plataformas digitais, destinado a conectá-los para prestação e contratação de serviços diversos, também podendo ser encontrado ao longo do documento apenas como “Serviços-trabbe”.

Serviços diversos - a variedade de serviços que pode ser prestada por um usuário a um anunciante.

Job – a demanda publicada pelo anunciante a ser prestada por um usuário.

Condições especiais do Job – Informações específicas sobre um determinado job publicado pelo anunciante, como: descrição da demanda, prazo para entrega, critérios de qualidade e aprovação do job, critérios para aceitação do usuário para atender o job, outras condições eventuais e prazo para pagamento.

Aceite - o ato do usuário concordar com as condições especiais do job e se candidatar ao job.


1.	DAS CONDIÇÕES GERAIS 

Estes Termos e Condições de Uso ("Termos de Uso") regem o acesso e uso por todas as pessoas físicas e/ou jurídicas usuárias, no Brasil, das plataformas digitais (website e aplicativo) com conteúdo que tem a finalidade específica de conectar prestadores de serviços (usuário) com terceiros interessados na contratação destes serviços (anunciante), a serem disponibilizados por meio das plataformas digitais  da TRABBE TECNOLOGIA LTDA., pessoa jurídica de direito privado, com sede no município de São Paulo, Estado de São Paulo, Rua Bacaetava, nº 66 apto. 94, Vila Gertrudes, CEP 04705-010, inscrita no CNPJ sob o nº 40.685.382/0001-59 ("trabbe"). 

Os usuários das plataformas digitais declaram estar cientes, ter pleno entendimento e concordância com regras deste Termos de Uso, na medida em que a contratação dos Serviços trabbe e serviços diversos está sendo realizada de maneira consentida e que sua aceitação é livre. 

O usuário compromete-se a ler integralmente este Termo de Uso e a Política de Privacidade, sendo a sua aceitação condição prévia para utilização das plataformas digitais e consequente contratação dos Serviços trabbe. 

Assim, ao contratar os Serviços trabbe e serviços diretos, haverá a imediata e prévia concordância com todas as determinações deste Termo de Uso e da Política de Privacidade da trabbe. Caso não concorde com todas as condições destes Termos de Uso, você não deve acessar, se cadastrar nas plataformas digitais, nem tampouco utilizar os Serviços trabbe ou serviços diversos. Nesse caso, se você já for usuário, o vínculo contratual deixará de existir, desde que não haja pendências.

A trabbe poderá alterar os termos relativos aos serviços a qualquer momento. Mas fique tranquilo! A trabbe avisará você sempre que uma nova versão atualizada dos termos for disponibilizada sobre os respectivos Serviços-trabbe. 

Mas fique atento! Você somente poderá continuar a usar as Plataformas digitais e os Serviços-trabbe, após o aceite da nova atualização do Termo de uso e da plena aceitação da nova versão publicada. A utilização dos Serviços caracterizará SUA aceitação a estes Termos.

No entanto, a nova versão não será aplicada aos Jobs em andamento.


 
Além de todo o descrito aqui nestes Termos de uso, outros documentos poderão ser aplicáveis sobre serviços trabbe, por exemplo, um Contrato específico firmado entre trabbe e você.


Fica certo e acordado, de forma irrevogável e irretratável, que a trabbe poderá, a seu exclusivo critério, rescindir de forma imediata e a qualquer momento a contratação efetuada dos Serviços trabbe, sem que sejam devidos pela trabbe quaisquer ônus, multas, penalidades, indenizações, danos morais ou materiais, lucros cessantes e/ou quaisquer direitos de qualquer ordem ou natureza. 

Nessa hipótese você receberá um aviso automático de resilição deste acordo e de exclusão imediata de sua participação como usuário dos serviços de intermediação e, consequentemente, das plataformas digitais, seja de que forma for. 

É necessária a utilização das informações disponibilizadas por você no momento da realização do cadastro para viabilizar o seu acesso e utilização das plataformas digitais. Tais informações serão utilizadas de acordo com a Política de Privacidade da trabbe. 

Caso você não concorde com a utilização de tais informações, você não poderá utilizar os serviços trabbe.

2.	DOS SERVIÇOS-TRABBE 


Os Serviços-trabbe Serviço provido pela trabbe para anunciantes e usuários, por meio de plataformas digitais, destinado a conectá-los para prestação e contratação de serviços diversos tais como serviços de advogados, referenciamento de clientes, atendimentos especializados, psicoterapia, informática e TI de qualquer espécie, entre outros.

Tais serviços diversos serão prestados de acordo com a capacitação e especialidade de cada usuários.

A trabbe será uma mera intermediadora para contratação dos serviços diversos. Não sendo fornecedora de quaisquer serviços anunciados por seus Usuários nas Plataformas.

Fica certo e ajustado que os usuários e anunciantes são pessoas totalmente distintas da trabbe, que com esta mantém, única e exclusivamente, uma relação contratual especifica de intermediação de prestação de serviços e fornecimento de plataforma digital por parte da trabbe. 


3.	DO FUNCIONAMENTO DA PLATAFORMA

O anunciante após acessar a plataforma e criar a sua conta, pode publicar seus Jobs para serem executados;
O usuário cadastrado na plataforma digital da trabbe, pode se candidatar aos Jobs que deseja prestar. 
O anunciante avalia e aprova a candidatura do usuário de acordo com suas necessidades e realiza o pagamento na trabbe. 
Após ter sua candidatura aprovada, o usuário selecionado receberá os materiais e informações necessárias para iniciar o trabalho.
O job, anunciante e usuário devem informar na plataforma a conclusão do job.
O anunciante e usuário avaliam o serviço que foi prestado. 
Ao final, não havendo divergências apontadas pelo anunciante, o usuário receberá o pagamento no prazo estipulado e poderá realizar a transferência para suas contas pessoais.

Havendo controvérsias e conflitos entre anunciante e usuário a trabbe, será mediadora e atuará no tratamento e soluções de tais controvérsias e conflitos, podendo reter o pagamento dos jobs prestados pelo usuário para o anunciante enquanto as controvérsias e/ou conflitos não forem sanados. Ao final, a trabbe efetuará a transferência do valor relacionado ao Job para o usuário devolvê-lo para o anunciante.



4.	DAS CONDIÇÕES DO RELACIONAMENTO ENTRE TRABBE, ANUNCIANTE E USUÁRIO


usuário e trabbe – A trabbe realizará apenas o Serviço-trabbe, por meio de suas plataformas digitais, limitando a disponibilização de espaço virtual para que os usuários possam, respeitando as condições especiais e a sua real capacidade técnica e disponibilidade de tempo para realização, candidatar-se ao job escolhido, publicado pelos anunciantes, sem ônus a trabbe. 

A trabbe, não interferirá diretamente na publicação dos jobs, não sendo, nesta qualidade, contratante de quaisquer Serviços publicados ou aceitos por seus Usuários na Plataforma.

A trabbe não se responsabiliza e nem garante a qualidade dos serviços prestados pelo usuário.

Anunciante e trabbe – A trabbe disponibilizará suas plataformas digitais para que o anunciante publique seus jobs e obtenha a candidatura dos usuários. 

A trabbe, não interferirá diretamente nas candidaturas ao jobs, não sendo, nesta qualidade, contratante de quaisquer Serviços publicados ou aceitos por seus Usuários na Plataforma nem fornecedora de quaisquer Serviços diversos desenvolvidos para cumprimento dos jobs.

Usuário e anunciante - O Prestador de Serviços Independente (usuário) deve verificar as condições especiais do job publicado pelo Contratante de Serviços Diversos (anunciante) antes de seguir com a prestação dos serviços diversos, atentando-se as regras estipuladas pelo anunciante.
 
Ao aceitá-las, o usuário concordará em cumprir as condições propostas pelo anunciante e dará seguimento à sua candidatura para prestação do job. 

Caso o usuário não cumpra as regras publicadas pelo anunciante em relação ao job, o anunciante terá o direito de não aprovar a conclusão do job e poderá julgá-lo como não realizado e consequentemente o usuário perderá o direito ao pagamento pelo job. 

No caso de mais de um usuário se candidatar para o mesmo job, caberá exclusivamente ao anunciante a escolha de qual usuário realizará a prestação do serviço. 

A trabbe, não interferirá diretamente na publicação ou na efetivação das candidaturas aos jobs, não sendo, nesta qualidade, contratante ou fornecedora de quaisquer Serviços publicados ou aceitos por seus Usuários na Plataforma

5.	DAS RESPONSABILIDADES ANUNCIANTE E DECLARAÇÕES 

O anunciante declara que todos os jobs serão publicados com a expectativa real de recebimento de prestação dos serviços diversos por parte dos usuários e que prestará da melhor forma possível e em boa-fé as informações necessárias para realização do job publicado.

Caberá ao anunciante prestar informações necessárias e as condições especiais para a realização do job, tais como a descrição detalhada da demanda, todas as condições relacionadas a prestação dos serviços, prazo para entrega, critérios de qualidade e aprovação do job e condições para pagamento, e ainda avaliar e aprovar a candidatura do usuário de acordo com suas necessidades

O anunciante declara ciência que estão proibidas a publicações de serviços ilegais de acordo com a legislação vigente ou que possam ser considerados ofensivos a terceiros.

O anunciante declara também que analisará de boa-fé os serviços prestados pelos usuários e disponibilizará os valores para pagamento dos usuários que realizarem os serviços diversos segundo as informações do Job publicado.

Assim, o pagamento a ser realizado pelo anunciante ao usuário será feito por intermédio da trabbe, sendo que o valor a ser pago deverá estar previamente disponível em conta específica da trabbe, para posteriormente e no momento oportuno, ocorrer o repasse ao usuário.


6.	DAS RESPONSABILIDADES DO USUÁRIO E DECLARAÇÕES


O usuário por sua vez, declara que em boa-fé se candidatará a jobs para os quais, segundo as condições que foram determinadas pelo anunciante, tenha real capacidade técnica e disponibilidade de tempo para realização.

Assim o usuário deverá sempre analisar todas as condições especiais do job antes de dar o aceite, se candidatando para a realização do job.

 Que após a aprovação da sua candidatura para realização de um job, na melhor forma possível, concluirá os Jobs.

O usuário tem ciência que receberá a remuneração de acordo com o detalhamento do job desenvolvido.  

O usuário declara ainda que compreende e concorda que, na hipótese de não desenvolvimento e/ou não conclusão do job, de acordo com as condições especiais do Job, não receberá a remuneração que lhe seria devida pela realização pelo job para o qual se candidatou.


7.	DAS RESPONSABILIDADES E LIMITAÇÕES DE RESPONSABILIDADE DA TRABBE


A trabbe não poderá ser responsabilizada pelo efetivo cumprimento das obrigações assumidas pelos usuários.

A trabbe não é responsável pela execução dos jobs publicados pelos anunciantes.

Cabe a trabbe a intermediação do pagamento entre o anunciante e o usuário.  O anunciante deverá, via de regra, ter a quantia previamente disponibilizada em sua conta junto a trabbe no momento da publicação do job. 

A trabbe somente efetuará o pagamento ao usuário após aprovação do anunciante, conforme estipulado nas características do job. 

Na qualidade de usuário das plataformas digitais, este reconhece que a trabbe é uma mera intermediadora e fornecedora de plataforma digital, não sendo a trabbe prestadora de qualquer outro serviço que não esses, devendo estar claro que os Prestadores de Serviços Independentes (usuários) e os Contratante dos Prestadores de Serviços (anunciantes), não atuam como mandatários da trabbe, não possuindo quaisquer poderes para agir em nome da trabbe seja de que forma for. 

A trabbe, nessa qualidade exclusiva de intermediadora e fornecedora de plataforma digital, não tem quaisquer responsabilidades e/ou obrigações, sejam de que natureza e/ou espécie forem, tais como de ordem cível, comercial, criminal, penal, consumerista, contratual, técnica, previdenciária, trabalhista, fiscal, ambiental ou de qualquer outra natureza, relacionadas, direta ou indiretamente, à qualidade, segurança, obtenção dos resultados almejados, eventuais prejuízos e/ou consequências negativas, passivos ou contingências/eventualidades de qualquer ordem ou natureza que sejam decorrentes dos serviços de Terceiros, a serem exclusivamente prestados de forma independente e individual por cada usuário ao anunciante.

As contingências/eventualidades são de responsabilidade única e exclusiva de cada usuário que prestou os serviços e não poderão, de forma alguma e sob qualquer pretexto, serem reclamadas e/ou exigidas da trabbe, de forma direita ou indireta, subsidiariamente, judicial ou extrajudicialmente.

A trabbe não tem quaisquer obrigações e/ou responsabilidades, sejam de que natureza for, por garantir a qualidade dos serviços prestados e/ou que estes serviços serão prestados de forma correta, completa e/ou adequada e pontual pelo usuário, atendendo ao esperado por você e/ou solucionando, de forma total ou parcial, o fato que levou à contratação. Desse modo, não há qualquer garantia de qualidade dos serviços prestados pelo usuário, os quais caberão exclusivamente ao usuário, na medida em que serão prestados por conta e risco exclusivos de cada usuário.

Dessa forma, a trabbe não será responsável por nenhum dano, obrigação e/ou prejuízo decorrente do uso feito pelo anunciante dos serviços realizados pelos presta realizados pelos prestadores de serviços independentes.


O anunciante e o usuário, na qualidade de usuários dos serviços trabbe, concordam que todo o risco decorrente deste uso será exclusivamente de sua responsabilidade, na medida em que a publicação pelo anunciante e a candidatura do usuário são efetuadas por sua livre escolha, por sua conta, risco e responsabilidade exclusivos, reconhecendo a trabbe como mera fornecedora de serviços de intermediação e disponibilização da plataforma digital para publicação dos jobs pelos anunciantes e candidatura dos usuários.


A trabbe, desse modo, não será responsável por danos indiretos, incidentais, especiais, punitivos ou emergentes, inclusive lucros cessantes, danos morais ou patrimoniais relacionados, associados ou decorrentes de qualquer uso dos Serviços de Terceiros. Vale 
esclarecer que as limitações e não concessão de garantias aqui efetuadas respeitam integralmente a legislação aplicável e, de nenhuma forma, possuem o objetivo de limitar responsabilidades e/ou alterar direitos, de acordo com a legislação aplicável.


8.	CANCELAMENTO DE SERVIÇOS E JOBS

O cancelamento de serviços diversos pelo anunciante ou cancelamento de candidaturas pelo usuário pode ser realizado por solicitação destes, de forma parcial ou total, nos prazos abaixo:

1.	Pelo anunciante: 24 horas contadas da publicação pelo anunciante, desde que nenhum candidato tenha sido aprovado para o job. Caso nenhum usuário tenha se candidatado ao job, este prazo é excluído, podendo o cancelamento ser feito a qualquer momento;

2.	Pelo usuário: 24 horas contadas da candidatura pelo usuário ao job publicado.

Após início da jornada de trabalho, poderá ocorrer cobrança de taxa sobre os cancelamentos e/ou recusas de Jobs.

A trabbe monitorará os pedidos de cancelamento e poderá concordar ou recusar o pedido de cancelamento, quando for o caso, ou ainda estabelecer os procedimentos para sua concretização.

Os cancelamentos ou recusas de jobs poderão estar condicionados à existência de créditos suficientes na conta do usuário (anunciante ou usuário) para que seja possível a compensação do respectivo valor a ser pago e/ou taxa de cancelamento e dependerá de autorização da trabbe.

9.	PAGAMENTO DO PREÇO PELOS SERVIÇOS DE TERCEIROS (usuário)

Fica certo e ajustado que os interessados na contratação destes serviços diversos (anunciante) entendem que tais serviços prestados pelos Prestadores de Serviços Independentes (usuário), contratados por meio dos serviços de intermediação da trabbe, poderão ser cobrados ("Preço"). 

Após a prestação do serviço pelo usuário e aprovação dos serviços pelo anunciante, a trabbe facilitará o pagamento do respectivo Preço ao usuário, agindo na qualidade de agente limitado de cobrança do Prestadores de Serviços Independentes (usuário). 

O pagamento do Preço feito ao usuário, por intermédio da trabbe e feito pela plataforma digital, será considerado como pagamento feito diretamente do anunciante para o usuário. O preço incluirá todos os tributos exigidos por lei. O preço pago pelo anunciante interessado na contratação destes serviços é final e não reembolsável, a menos que diversamente determinado pela trabbe. 

O Preço a ser pago ao usuário é devido e o anunciante deverá pagá-lo.  A forma de pagamento deverá estar expressa no Job e os valores devem ser disponibilizados a trabbe para futura transferência ao usuário, assim que o job for publicado.

Caso o método de pagamento indicado na conta do anunciante tenha expirado, for inválido ou não puder ser cobrado, o anunciante concorda que a trabbe, na qualidade de agente limitado de cobrança do Prestadores de Serviços Independentes (usuário), poderá usar um método secundário de cobrança (ou transferência) na conta, se houver.

O anunciante e o usuário terão o prazo de 30 (trinta) dias corridos a contar da data da conclusão do job (serviço prestado), para apontar qualquer diferença nos valores a crédito ou a débito que compõem o pagamento efetuado. 

Terá ainda, o mesmo prazo, contando-se da data em que o pagamento deveria ter sido efetuado de acordo com as regras definidas, para solicitar explicações de pagamentos não realizados. Findo esse prazo, a quitação do valor do serviço prestado será considerada irrestrita e irrevogável. 


10.	PAGAMENTO DOS SERVIÇOS-TRABBE 

Pelo serviço de intermediação realizado pela trabbe será cobrada uma Tarifa de intermediação correspondentes a XX% (XX porcento) sobre cada Job realizado por intermédio da plataforma, que somente será paga à trabbe quando a negociação/job se concretizar. 

O cadastro nas plataformas digitais trabbe será gratuito, no entanto será devido a trabbe pelo uso da plataforma, o valor R$xx,00 (xx reais) por cada job publicado pelo anunciante e de R$ xx,00 (xx reais) por cada job realizado pelo usuário por intermédio da plataforma. Mas fique tranquilo! Você somente pagará a trabbe quando a negociação/job for concluída.

O usuário e anunciante concordam em pagar à trabbe os valores correspondentes a qualquer serviço prestado pela trabbe em que haja uma tarifa estabelecida.
A trabbe se reserva o direito de modificar, aumentar ou excluir tarifas vigentes a qualquer momento ou durante promoções e outras formas transitórias de alteração dos preços praticados. 
As possíveis alterações aqui citadas não se aplicarão às negociações e jobs já iniciados na data em que tais alterações forem publicadas. Para estes, os Termos e condições gerais de uso valerão com a redação anterior.
A trabbe se reserva o direito de tomar as medidas judiciais e extrajudiciais pertinentes para receber os valores devidos.


11.	REGRAS PARA ABERTURA E MANUTENÇÃO DA CONTA DO USUÁRIO

Será necessário para início de utilização das plataformas digitais que os interessados na contratação destes serviços (anunciante) e Prestadores de Serviços Independentes (usuário), realizem seus registros nas plataformas digitais da forma ali indicada, sendo obrigatória a manutenção de uma conta pessoal de usuário ("Conta"). Para abertura da conta devem ser respeitadas as seguintes regras:

a) ter, pelo menos, 18 anos ou a maioridade exigida por lei em seu foro (se for diferente de 18 anos);

b) apresentação de todas as informações pessoais indicadas no app, tais como seu nome, endereço, número de telefone celular e indicação da idade, assim como pelo menos um método de pagamento válido (cartão de crédito ou parceiro de pagamento aceito);

Assim, os usuários concordam de forma irrevogável em:

i.	manter informações corretas, completas e atualizadas em sua conta, caso você não mantenha informações corretas, completas e atualizadas em sua conta, inclusive na hipótese de o método de pagamento informado ser inválido ou expirado, você poderá ficar impossibilitado de acessar e usar os serviços oferecidos pela trabbe;

ii.	É vedada a criação de mais de um cadastro por usuário, assim, somente será permitida a vinculação de um cadastro a um CPF ou CNPJ, um telefone e um e-mail, não podendo haver duplicidade de dados em nenhum caso; 

iii.	Em caso de eventuais multiplicidades de cadastros elaborados por um só usuário, a trabbe reserva-se o direito de a seu exclusivo critério e sem necessidade de prévia anuência ou comunicação aos usuários, inabilitar todos os cadastros existentes e impedir eventuais cadastros futuros vinculados a estes.

iv.	A trabbe pode unilateralmente excluir o cadastro dos usuários quando verificado que a conduta do usuário é ou será prejudicial ou ofensiva a outros usuários, a trabbe e seus funcionários ou a terceiros e nos termos do item 12.

v.	Qualquer Usuário ou pessoa física ou jurídica que se sentir ofendido por qualquer job ou avaliação publicado na Plataforma poderá contatar a trabbe pelos seus canais de atendimento, para que essa avalie e adote as medidas cabíveis sobre aquela publicação.

vi.	Você reconhece que a sua conta é pessoal e intransferível, e deve ser utilizada exclusivamente por você, sendo inadmissível e não permitida a utilização da sua conta pessoal por terceiros. Caso você permita que isso ocorra, será sob sua integral responsabilidade, conta e risco e seu usuário poderá ser excluído pela trabbe.

Ademais, todas as atividades realizadas na sua conta, seja de que forma for e independentemente do custo incorrido com esta utilização, são de sua integral e exclusiva responsabilidade, conta e risco, sendo que como usuário você concorda em manter sempre a segurança e o sigilo do nome de usuário e senha da sua própria conta.

Ao criar uma conta, você concorda que as plataformas digitais poderão lhe enviar correios eletrônicos ou offlines e/ou mensagens de texto informativas (SMS) ou via WhatsApp ou qualquer outro canal, como parte das operações comerciais regulares para o uso dos Serviços de Intermediação.

12.	SISTEMA DE QUALIFICAÇÃO DOS USUÁRIOS

a)	Você, usuário, aceita e se submete ao sistema de qualificação adotado pelo anunciante;

b)	O usuário concorda que a trabbe poderá cancelar, excluir ou suspender por tempo indeterminado cadastros que apresentem qualificações negativas de forma reiterada;

c)	Não caberá ou será devido ao usuário qualquer tipo de indenização ou ressarcimento por perdas e danos, lucros cessantes e danos morais, em razão da qualificação atribuída aos job publicados;

d)	A trabbe não excluirá comentários ou qualificações sobre os usuários. Somente o usuário responsável pelo comentário ou qualificação poderá remover ou alterar os comentários ou qualificações. 

e)	Mas a trabbe se reserva o direito de excluir unilateralmente e a seu exclusivo critério, comentários que contenham dados de cadastros de usuários ou que se tornem prejudiciais ou ofensivos a outros usuários, a trabbe e seus funcionários ou a terceiros;

f)	Tendo em vista que os comentários postados são opiniões pessoais dos usuários, estes serão responsáveis pelas opiniões publicadas na Plataforma, seja perante a trabbe, perante os usuários, anunciantes, terceiros, órgãos governamentais ou demais usuários da plataforma, isentando a trabbe de qualquer responsabilidade relativa à veiculação dos comentários;

g)	A trabbe também se reserva o direito de excluir, unilateralmente e a seu exclusivo critério, comentários e cadastros de Usuários que pratiquem ou contenham:

i.	Ofensa à honra, imagem, reputação e dignidade de terceiros;
ii.	Pornografia, pedofilia, e outras modalidades de satisfação sexual;
iii.	Racismo ou discriminação de qualquer natureza;
iv.	Bullying, Stalking ou qualquer outra espécie de constrangimento ilegal ou assédio;
v.	violação a direito autoral ou direito de imagem;
vi.	Utilização de marcas, símbolos, logotipos ou emblemas de terceiros;
vii.	Instigação ou apologia à prática de crimes, como tráfico ou uso de entorpecentes, estupro, homicídio, estelionato, dentre outros;
viii.	Erros ou suspeita de equívocos.


13.	SANÇÕES

Sem prejuízo de outras medidas, a trabbe poderá, a seu exclusivo critério e sem necessidade de prévia anuência ou comunicação aos usuários, advertir, suspender ou cancelar, temporária ou permanentemente, o cadastro ou os anúncios do usuário, podendo aplicar sanção que impacte negativamente em sua reputação, a qualquer tempo, iniciando as ações legais cabíveis e suspendendo a prestação de seus serviços, se: 

a) o usuário não cumprir qualquer dispositivo destes Termos e as demais políticas da trabbe; 

b) descumprir com seus deveres de usuário; 

c) praticar atos delituosos ou criminais; 

d) não puder ser verificada a identidade do usuário, qualquer informação fornecida por ele esteja incorreta ou se as informações prestadas levarem a crer que o cadastro seja falso ou de pessoa diversa; 

e) A trabbe entender que os anúncios ou qualquer outra atitude do usuário tenham causado algum dano a terceiros ou a trabbe ou tenham a potencialidade de assim o fazer.

f) Nos casos de suspensão, temporária ou permanente, do cadastro, todos os anúncios ativos e as ofertas e aceites realizados serão automaticamente canceladas;

g) Em caso de suspensão permanente, a trabbe devolverá quaisquer valores devidos a serem apurados desde o cadastro até a efetiva exclusão, no prazo de até 60 dias;

h) Em caso de suspensão temporária, o cadastro do usuário será suspenso até a reabilitação de acordo com a política estabelecida pela trabbe, quando voltará a viger normalmente;

i) Em caso de suspensão permanente ou exclusão do Prestador em razão de comprovação ou suspeita de fraude contra a trabbe ou a outros usuários, a trabbe poderá reter até XX% dos eventuais valores pendentes de liberação.


14.	AUTONOMIA DAS PARTES 

Estes termos não geram entre as Partes nenhuma espécie de sociedade, associação, solidariedade obrigacional, tampouco acarreta às Partes qualquer tipo de responsabilidade, direta ou indireta, de natureza societária, comercial, tributária, trabalhista, previdenciária ou de qualquer outra natureza, nem configura alienação ou sucessão, seja entre as Partes, seus administradores, empregados, contratados ou prepostos, seja perante terceiros, restando preservada a autonomia jurídica e funcional de cada Parte.

Todos os riscos decorrentes do negócio e da atividade previstos nestes Termos, sejam eles comerciais, financeiros, de responsabilidade perante fornecedores e associados e cumprimento da legislação brasileira, por débitos tributários, trabalhistas, obtenção perante os Poderes Públicos, Agências e Órgãos Reguladores competentes de eventuais licenças para a execução do objeto do negócio e outros, correm por conta exclusiva e às expensas de cada Parte, não recaindo sobre a trabbe qualquer relação, vínculo ou responsabilidade, nem mesmo subsidiária ou solidária, seja a que título for. 

A consecução do objeto deste Contrato não criará, em hipótese alguma, qualquer vínculo empregatício entre a trabbe e quaisquer dos usuários, seus sócios, coligados, parceiros, prepostos, colaboradores, ou contratados a qualquer título. 




15.	 FALHAS NO SISTEMA

A trabbe não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo Usuário em razão de falhas na internet, no sistema ou no servidor utilizado pelo Usuário, decorrentes ou não de condutas de terceiros, caso fortuito ou força maior. A trabbe também não será responsável por qualquer vírus que possa atacar o equipamento do Usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos, vídeos ou áudio.

16.	SOLUÇÃO DE CONTROVÉRSIAS
A trabbe disponibiliza uma estrutura de atendimento, composta por ferramentas de contato e uma equipe especializada de suporte para o tratamento de reclamações, controvérsias e/ou conflitos com o objetivo de evitar a judicialização de controvérsias que, eventualmente, possam surgir com o o uso dos serviços trabbe.
Diante disso, os usuários das plataformas digitais se comprometem a utilizar as ferramentas de suporte disponibilizadas pela trabbe como primeiro meio para a solução de controvérsias decorrentes do seu uso dos Serviços.

17.	DIREITOS DE PROPRIEDADE INTELECTUAL 

O uso comercial das expressões "trabbe" “usuário”, “anunciante”, como marca, nome empresarial ou nome de domínio, bem como os logos, marcas, insígnias, conteúdo das telas relativas aos serviços da Plataforma e o conjunto de programas, bancos de dados, redes e arquivos que permitem que o Usuário acesse e use sua conta, são propriedade da trabbe e estão protegidos pelas leis e pelos tratados internacionais de direito autoral, de marcas, de patentes. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo com autorização expressa da trabbe.

Dessa forma é vedada a utilização de dispositivo, software ou outro recurso que possa interferir nas atividades e nas operações de Plataforma, bem como nas publicações, nas descrições, nas contas ou em seus bancos de dados. Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e as proibições estipuladas nestes Termos tornará o responsável passível de sofrer as sanções legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável por indenizar a trabbe ou seus Usuários por eventuais danos causados.

Cada Parte detém individualmente seus respectivos Direitos de Propriedade Intelectual sobre imagens, logotipo, designs, sinais distintivos, sendo que nenhum direito será transferido ou licenciado à outra Parte, a não ser aquelas disposições que autoriza a trabbe a divulgar o usuário como parte da sua rede, ou através de contratos especificamente para este fim, os quais deverão ser formalizados por escrito e assinado por ambas as Partes e por duas testemunhas.





18.	LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO

Todos os itens destes Termos e condições gerais de uso são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a estes Termos e condições gerais de uso, as partes concordam em se submeter ao Foro Central da Cidade de São Paulo/SP.


19.	DISPOSIÇÕES GERAIS.
Você não poderá ceder, nem tampouco transferir estes Termos, total ou parcialmente, sem prévia aprovação por escrito da trabbe. Você concorda que a trabbe ceda e transfira estes Termos, total ou parcialmente, inclusive: (i) para uma subsidiária ou afiliada; (ii) um adquirente das participações acionárias, negócios ou bens da trabbe; ou (iii) para um sucessor em razão de qualquer operação societária. Não existe joint-venture, sociedade, emprego ou relação de representação entre você, a trabbe ou quaisquer Parceiros Independentes como resultado do contrato entre você e a trabbe ou pelo uso dos Serviços.
As partes reconhecem que a nulidade ou invalidade de qualquer disposição contida neste Termo não prejudicará a validade e eficácia das demais disposições e do próprio Termo.
Nesse caso, as partes substituirão a disposição invalida ou nula, ou parte dela, por outra que seja legal, válida e exequível e que, na medida possível, tenha efeito similar à aquela, para fins de conteúdo e finalidade dos presentes Termos. 

Caso precise, você também poderá esclarecer dúvidas e requisitar informações sobre a trabbe e seus serviços em nossos canais de atendimento e  sobre a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), ao nosso Encarregado de Proteção de Dados, através do e-mail  dpo@trabbe.comou ainda pelo canal "fale conosco" ou pelo atendimento@trabbe.com.


    `

    const paragrafos = texto.split('\n').map((paragrafo, index) => (
        <Paragraphy key={index}>{paragrafo}</Paragraphy>
      ));

    return (
        <WrapperPage id='top'>
            <section
                style={{backgroundColor: '#fff'}}
                class="hero is-light is-fullheight">
                    <div class='div'>
                            <div class='wrapper nav-banner'>
                                <nav class='navbar '>
                                    <Header> Termos e <span> condições</span></Header>
                                </nav>
                            </div>
                        </div>
                <div class="hero-body">
                    <div class='container container-termos-politicas'>
                        <div class='column'>
                        <Content>
                            {paragrafos}
                        </Content>
                        </div>
                    </div>
                </div>
            </section>
        </WrapperPage>    
    )
}


export default TermosPoliticies