import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Link } from 'react-router-dom';
import { Person } from '@mui/icons-material';



export const OurBlog = styled.h1`
    font-size: 40px;
    font-weight: bold;
    

    span {
        background: transparent linear-gradient(108deg, #DC6C61 0%, #381679 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        color: transparent;
    }

`;


export const TitleHeaderBlog = styled.h1`
    font-size: 40px;
    color: #000;
    text-align: center;
    font-weight: bold;
`;

export const TitleBlog = styled.h2`
    margin-top: 9px;
    padding: 10px 0;
    color: ${colors.blackgray};
    font-weight: 400;
    max-width: 594px;
    display: flex;
    flex-direction: column;
    font-size: 40px;
    line-height: 1.2;

    span {
        font-weight: bold;
    }

`;

export const TitleContentBlog = styled.p`
    font-size: 24px;
    font-weight: 400;
    line-height: 2rem;
    max-width: 594px;
    margin-top: 16px;
`

export const PersonHeader = styled.img`
    position: relative;
    top: 70px;

`;


export const BlogBody = styled.div`
    height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
`;

export const CardTitle = styled.h1`
    font-size: 20px;
    font-weight: 500;

`;

export const CardContent = styled.p`
    margin-top: 5px;
    font-size: 20px;

`;


export const Content = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    position: relative;
    margin-bottom: 20px;
    overflow: hidden;

    @media only screen and (min-width: 375px) {

${OurBlog} {
    font-size: 32px;
}

${TitleBlog} {
    font-size: 32px !important;
    max-width: 593px;

}

${TitleHeaderBlog} {
        font-size: 32px;
    }

${BlogBody} {
    h1 {
        font-size: 32px;
        margin-top: 0px;

    }
   
}

${TitleContentBlog} {
    font-size: 20px;
    margin-top: 5px;
}

${CardTitle} {
    font-size: 18px !important;
    margin-top: 5px;
}

${CardContent} {
        font-size: 18px;
    }

}

    @media only screen and (min-width: 425px) {

${OurBlog} {
    font-size: 32px;
}

${TitleBlog} {
    font-size: 32px !important;
    max-width: 593px;

}

${TitleHeaderBlog} {
        font-size: 32px;
    }

${BlogBody} {
    h1 {
        font-size: 32px;
        margin-top: 0px;

    }
   
}

${TitleContentBlog} {
    font-size: 20px;
    margin-top: 5px;
}

${CardTitle} {
    font-size: 18px !important;
    margin-top: 5px;
}

${CardContent} {
        font-size: 18px;
    }

}

    @media only screen and (min-width: 768px) {

${OurBlog} {
    font-size: 36px;
}

${TitleBlog} {
    font-size: 36px !important;
    max-width: 593px;

}

${TitleHeaderBlog} {
        font-size: 36px;
    }

${BlogBody} {
    h1 {
        font-size: 36px;
        margin-top: 0px;

    }
   
}

${TitleContentBlog} {
    font-size: 22px;
}

${CardTitle} {
    font-size: 20px !important;
}

${CardContent} {
        font-size: 20px;
    }

}

@media only screen and (min-width: 1024px) {

${OurBlog} {
    font-size: 36px;
}

${TitleBlog} {
    font-size: 36px !important;
    max-width: 593px;

}

${TitleHeaderBlog} {
        font-size: 36px;
    }

${BlogBody} {
    h1 {
        font-size: 36px;
        margin-top: 0px;

    }
   
}

${TitleContentBlog} {
    font-size: 22px;
}

${CardTitle} {
    font-size: 20px !important;
}

${CardContent} {
        font-size: 20px;
    }

}

@media only screen and (min-width: 1440px) {

    ${OurBlog} {
        font-size: 40px;
    }

    ${TitleBlog} {
        font-size: 40px !important;
        width: 593px;

    }

    ${TitleHeaderBlog} {
        font-size: 40px;
    }


    ${BlogBody} {
        height: 110vh;
        h1 {
            font-size: 40px;
            margin-top: 0px;

        }
       
    }

    ${TitleContentBlog} {
        font-size: 24px;
    }

    ${CardTitle} {
        font-size: 22px !important;
    }

    ${CardContent} {
        font-size: 22px;
    }
    
}

@media only screen and (min-width: 1980px) {

${OurBlog} {
    font-size: 40px;
}

${TitleBlog} {
    font-size: 40px !important;
    width: 593px;

}

${TitleHeaderBlog} {
    font-size: 40px;
}


${BlogBody} {
    height: 110vh;
    h1 {
        font-size: 40px;
        margin-top: 0px;

    }
   
}

${TitleContentBlog} {
    font-size: 24px;
}

${CardTitle} {
    font-size: 22px !important;
}

${CardContent} {
    font-size: 22px;
}

${PersonHeader} {
    top: 160px;
}

}

`;


// BLOG

export const WrapperLista = styled.div`
    width: 100%;
    overflow-x: auto;

    @media only screen and (min-width: 1280px) and (max-height: 720px) {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
   
    }


`;


export const WrapperDate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${colors.blackgray};
    width: 60%;
    margin-top: 10px;

    @media only screen and (min-width: 375px){
       width: 100%;

    }   


    @media only screen and (min-width: 1024px){
       width: 100%;

    }   

    @media only screen and (min-width: 1440px){
        width: 70%;

    }   

    
`;

export const ContentDate = styled.div`
    font-size: 16px;
`;

export const CircleDate = styled.div`
    width: 15px;
    height: 15px;
    /* UI Properties */
    background: transparent linear-gradient(180deg, #FF644D 0%, #2E186A 100%) 0% 0% no-repeat;
    border-radius: 100%;
    opacity: 1;

`;

export const Lista = styled.ul`
    display: flex;
    justify-content: center;
    text-align: center;
    width: 95%;

    @media only screen and (min-width: 0px) and (max-width: 374px) {
        width: 320% !important;
    }

    @media only screen and (min-width: 375px) and (max-width: 499px) {
        width: 300% !important;
    }

    @media only screen and (min-width: 500px) and (max-width: 667px) {
        width: 170% !important;
    }

    @media only screen and (min-width: 667px) and (max-width: 1080px) {
        width: 150% !important;
   
    }
`;

export const LinkPage = styled(Link)`
    background-color: ${(props) => (props.isActive ? `${colors.main}` : `transparent`)};
    color: ${(props) => (props.isActive ? `${colors.white}` : `${colors.blackgray}`)};
    margin-left: 20px;
    border-radius: 24px;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: center;
    margin-right: auto; 
    margin-left: auto;
   

`;

export const Item = styled.li`
    color: ${(props) => (props.isActive ? `${colors.white}` : `${colors.blackgray}`)};
    width: 150px;
    padding: 10px 0px;
    font-size: 18px;
   
`;

export const WrapperBlog = styled.div`
    margin-top: 40px;
`;

export const GridBlog = styled.div`
    display: grid;
    align-self: center !important;
    column-gap: 48px;
    row-gap: 10px;
    grid-template-rows: auto;
    justify-items: center;
    align-self: center;
    justify-self: center;
    width: 450px;

    @media only screen and (min-width: 768px){
        grid-template-columns: repeat(auto-fit, minmax(406px, 1fr)) !important;
   
    }

    @media only screen and (min-width: 1024px){
        grid-template-columns: repeat(auto-fit, minmax(406px, 1fr)) !important;
   
    }

    @media only screen and (min-width: 1440px){
        grid-template-columns: repeat(auto-fit, minmax(526px, 1fr)) !important;
   
    }
    
    
    
    

`;

export const CardBlog = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: left;
    color: #707070;
    font-family: 'Roboto', Arial, Helvetica, sans-serif; 
    
`;

export const CardImage = styled.div`
`;

export const ImageWrapper = styled.img`
    border-radius: 30px;
    object-fit: cover;
`;





export const ContentR = styled.div`
    width: 100%;
    text-align: center;
    display: flex;

`;










