import React, { useEffect } from 'react'


import {
    Content,
    Header, Paragraphy, WrapperPage
} from './styles'


import Footer from '../../components/footer';



export const PoliticiesPrivacity = () => {

    useEffect(() => {
        const topElement = document.getElementById('top'); // ou qualquer ID que você esteja usando para o topo
        topElement.scrollIntoView({ behavior: 'smooth' });
    }, [])

    let texto = `
    Introdução
A Trabbe valoriza privacidade e tem como compromisso proteger a sua privacidade e seus dados pessoais, nos termos da Lei Geral de Proteção de Dados e demais leis sobre o tema, bem como descrever de que forma sua privacidade é protegida pela Trabbe ao coletar, tratar, armazenar e descartar suas informações pessoais.
A Trabbe possui políticas internas com suas missões e os valores, elaboradas com o objetivo de orientar e retratar quais os valores prioritários, sempre visando uma atuação ética de todos os seus colaboradores.
Quando os usuários preenchem formulários nas plataformas digitais da Trabbe, os dados pessoais solicitados são mantidos em sigilo e serão utilizados, em diversas mídias, apenas para o propósito que motivou o envio, sem que sejam compartilhados com terceiros, sem a aprovação do titular. Porém, resguardado o direito de, se necessário, ou exigido fiscal, judicial ou administrativamente por autoridades governamentais, os dados serão fornecidos para cumprimento da legislação.
As informações fornecidas à Trabbe serão ainda divulgadas na medida necessária, notadamente para a melhor prestação dos serviços de intermediação pela Trabbe, bem como quando necessário e solicitado pela gestora do método de pagamento, nos casos de interpelação judicial, ou, de forma agregada. 

Para que coletamos seus dados

Salvo expressa disposição contrária, a submissão de seus dados à Trabbe na qualidade de Usuário é totalmente voluntária e você entende e declara reconhecer que a falta de qualquer dos dados solicitados poderá afetar a qualidade dos serviços de intermediação a serem prestados pela TRABBE. 
Os dados pessoais dos Usuários serão concedidos à Trabbe quando da realização do cadastro necessário à utilização das plataformas digitais preferencialmente com vistas a:
(i) manter uma adequada relação com o Usuário; 
(ii) facilitar o acesso ao serviço prestado pela Trabbe; 
(iii) prestar, gerenciar, administrar, ampliar e melhorar os serviços a serem prestados por meio das plataformas digitais; 
(iv) atender adequadamente qualquer consulta ou solicitação de informação realizada pelo Usuário;
(v) realizar ações de marketing podendo a Trabbe enviar conteúdo exclusivos, com a finalidade única de apresentar em primeira mão as novidades em suas soluções; e
(vi) realizar contato com o cliente quando este manifesta o interesse em receber um contato comercial;

Quais dados coletamos

Os dados pessoais coletados no cadastro de um novo usuário são: 
1. (i) nome completo;
2. (ii) número de telefone;
3. (iii) endereço completo;
4. (iv) endereço de e-mail;
5. (v) senha de acesso;
6. (vi) informações referentes ao meio de pagamento e dados bancários;
7. (vii) foto para identificação pessoal;
8. (viii) documento de identificação pessoal;
9. (ix) cookies. 

Os usuários das plataformas digitais e clientes estão cientes de que fornecem informações de forma voluntária por meio da aceitação de cookies, preenchimento e envio de formulários.
Faz-se necessário esclarecer que as plataformas digitais poderão usar cookies ou outras tecnologias de identificação para ajudar a personalizar a sua experiência.
O que são cookies
Cookies são arquivos de texto armazenados no navegador (browser) durante o uso da internet. São utilizados para coletar, armazenar e compartilhar dados das atividades realizadas durante as navegações em páginas da internet. Também permitem recordar experiências e customizações anteriores, aplicadas na página da Trabbe.
São utilizados dois tipos de cookies: de sessão, que são válidos apenas enquanto o browser permanecer aberto, e os persistentes, que permanecem armazenados no browser por tempo maior. Os cookies de sessão são utilizados para identificar o usuário durante suas visitas às plataformas digitais da Trabbe, já os cookies persistentes são utilizados quando é preciso identificar o usuário por um período maior.
Sites e aplicativos de terceiros
A segurança e sigilo das informações pessoais de nossos clientes, são de extrema importância. Em alguns casos, alguns serviços são realizados através de serviços terceirizados, que ocorre com o compartilhamento de algumas das informações fornecidas no momento do cadastro:
a) Parceiros de negócios: Empresas fornecedoras de serviços que auxiliam a aprimorar nossos serviços. São exemplos de fornecedores: servidores de e-mails, servidor de hospedagem e criação de ações de marketing.
b) Requisição judicial.
No entanto, a nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a política de privacidade do mesmo.
Embora a Trabbe utilize medidas de segurança e monitore seus sistemas para verificar vulnerabilidades e ataques para proteger seus dados pessoais contra divulgação não autorizada, mau uso ou alteração, o usuário entende e concorda que não há garantias de que as informações não poderão ser acessadas, divulgadas, alteradas ou destruídas por violação de qualquer uma das proteções físicas, técnicas ou administrativas.
Por quanto tempo a Trabbe irá armazenar os dados

A Trabbe manterá os dados pessoais coletados somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coletamos, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, ou requisição de autoridades competentes.
Para determinar o período de retenção adequado para os dados pessoais, consideramos a quantidade, a natureza e a sensibilidade dos dados pessoais, o risco potencial de danos decorrentes do uso não autorizado ou da divulgação de seus dados pessoais, a finalidade de processamento dos dados pessoais e se podemos alcançar tais propósitos através de outros meios, e os requisitos legais aplicáveis.
   
Alterações na Política de Privacidade e contato

A Trabbe reserva-se ao direito de alterar esta Política de Privacidade, bem como qualquer outra política a qualquer momento e ao seu exclusivo critério. 

Após a publicação da nova Política de Privacidade o usuário deverá realizar um novo aceite para que possa continuar a utilizar os serviços de intermediação fornecidos pela Trabbe. 

A TRABBE oferece aos titulares de dados pessoais as opções de exclusão, alteração, correção, restrição ou portabilidade de seus dados, bastando para isto entrar em contato conosco, através do e-mail:dpo@trabbe.com.
O Direito aplicável
O uso da internet é regulado, no Brasil, pelo Marco Civil da Internet (Lei Federal n. 12.965, de 23 de abril de 2014), regulamentado pelo Decreto n. 8.771, de 11 de maio de 2016, e o tratamento de dados pessoais é regulado pela Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais).
A LGPD é a lei nº 13.709, aprovada em agosto de 2018 e com vigência a partir de agosto de 2020.

    `

    const paragrafos = texto.split('\n').map((paragrafo, index) => (
        <Paragraphy key={index}>{paragrafo}</Paragraphy>
      ));

    return (
        
        <WrapperPage id='top'>
            <section
                style={{backgroundColor: '#fff'}}
                class="hero is-light is-fullheight">
                        <div class='div'>
                            <div class='wrapper nav-banner'>
                                <nav class='navbar '>
                                    <Header> Políticas de <span> privacidade</span></Header>
                                </nav>
                            </div>
                        </div>
                        <div class="hero-body">
                            <div className='container container-termos-politicas'>
                                <div class='column'>
                                    
                                    <Content >
                                        {paragrafos}
                                    </Content>
                                </div>
                            </div>
                        </div>
            </section>
            
        </WrapperPage>
    )
}


export default PoliticiesPrivacity