import React, { useEffect, useState } from 'react';
import {
    Header,
    Logo,
    Navbar,
    NavbarItem,
    ButtonPortal,
    RightSide,
    LinkPage,
    WrapperNavBar
} from './styles'


import LogoImage from '../../assets/icons/logo/trabbe_logo_laranja_horizontal.png'
import { useLocation, useNavigate } from 'react-router-dom';
import { useScroll } from 'framer-motion';

import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import { useEventContext } from 'context/context';
import zIndex from '@mui/material/styles/zIndex';

const HeaderComponent = () => {
    const portalURL = 'https://jobber.trabbe.com/';
    const location = useLocation();
    const navigation = useNavigate();

    const {scrollY} = useScroll()
    const [hiddenContent, setHiddenContent] = useState(false)
    const [tamanho, setTamanho] = useState({ width: window.innerWidth, height: window.innerHeight });

    const {hideHeader,  handleNavbar, setHideHeader } = useEventContext();
    const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);

  };

    useEffect(() => {
        


        const subs = scrollY.on('change', (latest) => {
            const previous = scrollY.getPrevious();
            if(latest < 199) {
                setHiddenContent(false)
            }
        })
        handleResize()
        return () => subs()
      }, [scrollY]);

      const handleResize = () => {
        setTamanho({ width: window.innerWidth, height: window.innerHeight });

         // Adiciona um ouvinte de evento para a mudança de tamanho da janela
        window.addEventListener('resize', handleResize);
    
        // Remove o ouvinte de evento quando o componente é desmontado
        return () => {
            window.removeEventListener('resize', handleResize);
        };
      };


    const handleNavbarHeader = () => {
        setIsActive(!isActive);
        setHideHeader(false)
    }

    return (
        <>
        
        <WrapperNavBar>
        <nav style={{height: `${window.innerWidth > 1024 ? '90px' : '70px'}`,padding: `${window.innerWidth < 1024 ? '0' : ' 0 32px'}`, zIndex: '9999', boxShadow: '0px 0px 8px #00000029'}} 
    className={`navbar is-fixed-top`} role="navigation" aria-label="main navigation">
    <div className="navbar-brand">
        <LinkPage className="navbar-item" to={'/merc'}>
        <Logo style={{maxHeight: '60px'}} src={LogoImage} alt="Logo" />
        </LinkPage>
    
        <span role="button"
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        className={`navbar-burger ${isActive ? 'is-active' : ''} is-hidden-desktop`}
        aria-label="menu"
        aria-expanded="false"
        onClick={handleNavbarHeader} aria-hidden="false">
            <MenuIcon sx={{fontSize: '2rem', color: '#FF644D'}} />
        </span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
    </div>

    <div className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className={`navbar-end  ${window.innerWidth < 100 ? 'is-flex' : 'none'}`}>
        <LinkPage className="navbar-item" onClick={() => {handleNavbarHeader()}} to={'/merc'} isActive={location.pathname === '/merc'}>
            Merc
        </LinkPage>
        <LinkPage className="navbar-item" onClick={() => {handleNavbarHeader()}} to={'/corp'} isActive={location.pathname === '/corp'}>
            Corp
        </LinkPage>
        <LinkPage className="navbar-item" onClick={() => {handleNavbarHeader()}} to={'/dados'} isActive={location.pathname === '/dados'}>
            Dados
        </LinkPage>
        <LinkPage className="navbar-item" onClick={() => {handleNavbarHeader()}} to={'/blog/'} isActive={location.pathname === '/blog/'}>
            Blog
        </LinkPage>
        <LinkPage className='center-button' onClick={() => {handleNavbarHeader()}} to={'https://jobber.trabbe.com/login'}>
                <ButtonPortal><div>acesse o portal</div></ButtonPortal>
        </LinkPage>
        </div>
    
    </div>
    </nav>
        </WrapperNavBar>


          
        </>
    )
}



export default HeaderComponent