
import React, { useEffect, useRef, useState } from 'react'
import { AppImage, ButterFly, Butterflies, CellPhone, Comunity, Content, ConteudoDigital, DownloadApple, DownloadGoogle, LinkPage, Merc, PlataformaText, RoseLeaf, RoseLeafRight, RotaText, TrabbeComunityContent, TrabbeImage, TrabbeTitle, TrabbeVideo, WrapperDownload, YoutubePlayer } from './styles'
import { useScroll } from 'framer-motion'

import BackgroundBarra from '../../assets/images/home_header/background.svg'
import PeopleImage from '../../assets/images/home_header/people.png'
import BackgroundComunity from '../../assets/images/trabbe_comunity/background.png'
import Logo from '../../assets/icons/logo/trabbe_logo_laranja_horizontal.png'
import RoseLeafImage from '../../assets/images/trabbe_video/leaf_rose.png';
import RoseLeafRightImage from '../../assets/images/trabbe_video/leaf_right_rose.svg';
import ButtlerFlyImage from '../../assets/images/trabbe_comunity/BG-BYX.png'
import CellPhoneImage from '../../assets/images/trabbe_comunity/BG-HAND-MOBILE.png'
import ButterFliesImage from '../../assets/images/trabbe_comunity/BG-BYX-REPEAT.png'



import DownloadGoogleImage from '../../assets/images/download_app/google.png'
import DownloadAppleImage from '../../assets/images/download_app/apple.png'
import YouTube, { YouTubeProps } from 'react-youtube';
import Footer from '../../components/footer'

const YoutubeVideo = () => {
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  return <YoutubePlayer style={{width: '90%'}} videoId="GzKurfoU6K0" opts={opts} onReady={onPlayerReady} />;
}




const HomePage = () => {
    const scrollRef = useRef(null)
    const {scrollY} = useScroll()

    const [hiddenContent, setHiddenContent] = useState(false)
    const [hiddenWhoTrabbe, setHiddenWhoTrabbe] = useState(false)
    const [hiddenWhoAnnunce, setWhoAnnunce] = useState(false)
    const [hiddenCellphone, setCellPhone] = useState(false)

    const [playVideo, setPlayVideo] =  useState(false)

      
    useEffect(() => {
        const topElement = document.getElementById('top'); // ou qualquer ID que você esteja usando para o topo
        topElement.scrollIntoView({ behavior: 'smooth' });

        const subs = scrollY.on('change', (latest) => {
            const previous = scrollY.getPrevious();
            if(latest > 0) {
                setHiddenContent(false)
                setHiddenWhoTrabbe(false)
            }
            if(latest > 32) {
                setHiddenWhoTrabbe(true)
            }
            if(latest > 699) {
                setHiddenContent(true)
                setWhoAnnunce(false)
            }
            if(latest > 819) {
                setWhoAnnunce(true)
                setHiddenWhoTrabbe(true)
                setCellPhone(false)
            }
            if(latest > 1520) {
                setHiddenWhoTrabbe(false)
            }
            if(latest > 1403) {
                setCellPhone(true)
            }
        })
        return () => subs()
      }, [scrollY]); // O array de dependências vazio garante que o efeito é executado apenas uma vez durante a montagem e desmontagem do componente
    

    return (
        <Content id='top'>
            <section
                 class="hero is-light is-fullheight quem-somos-banner">
                <div class="hero-body">
                    <div class='container'>
                    <div class='columns is-vcentered'>
                        <div class='column is-half' style={{marginTop: '80px'}}>
                        <Merc
                variants={{
                    visible: {
                        x: 0, 
                        opacity: 1, 
                        transition: {
                            delay: .4
                        }},
                        hidden: {
                            x: -50, 
                            opacity: 0,
                            transition: {
                                delay: .4
                        }
                    }
                }}
                initial={{ x: -50, opacity: 0}}
                animate={hiddenContent ? 'hidden' : 'visible'}
                transition={{delay: .4}}
                
                >Somos a <span>Trabbe</span></Merc>
                <RotaText
                variants={{
                    visible: {
                        x: 0, 
                        opacity: 1, 
                        transition: {
                            delay: .4
                        }},
                        hidden: {
                            x: -50, 
                            opacity: 0,
                            transition: {
                                delay: .4
                        }
                    }
                }}
                initial={{ x: -50, opacity: 0}}
                animate={hiddenContent ? 'hidden' : 'visible'}
                >Nos preocupamos com <span>O futuro das pessoas</span></RotaText>
                <PlataformaText
                variants={{
                    visible: {
                        x: 0, 
                        opacity: 1, 
                        transition: {
                            delay: .4
                        }},
                        hidden: {
                            x: -50, 
                            opacity: 0,
                            transition: {
                                delay: .4
                        }
                    }
                }}
                initial={{ x: -50, opacity: 0}}
                animate={hiddenContent ? 'hidden' : 'visible'}
                transition={{delay: .4}}

                >
                Atenta ao futuro, ajudamos nossos usuários a entregar o melhor para empresas, para a sociedade, para o mundo.
                </PlataformaText>
                <WrapperDownload>
                <LinkPage to={'https://play.google.com/store/apps/details?id=com.apptrabbe&pli=1'} target='_blank'>
                    <DownloadGoogle src={DownloadGoogleImage}></DownloadGoogle>
                </LinkPage>
                <LinkPage className='apple_download' to={'https://apps.apple.com/br/app/trabbe/id1569537685'} target='_blank'>
                    <DownloadApple src={DownloadAppleImage}></DownloadApple>
                </LinkPage>
                </WrapperDownload>
                            </div>
                        <div class='column'>
                        <AppImage
                        variants={{
                        visible: {
                            opacity: 1, 
                            transition: {
                                delay: .6
                            }},
                            hidden: {
                                opacity: 0,
                                transition: {
                                    delay: .4
                            }
                        }
                        }}
                        initial={{ opacity: 0 }}
                        animate={hiddenContent ? 'hidden' : 'visible'}
                        src={PeopleImage} />
                        </div>
                    </div>
                    </div>
                </div>
                <RoseLeafRight className='is-hidden-mobile' src={RoseLeafRightImage} />
            </section>
            <section
                 class="hero is-light is-fullheight quem-somos-trabbe">
                    
                <div class="hero-body">
                        
                    <div class='container'>
                        <div class='columns is-vcentered is-justify-content-space-between'>
                        <div class='column is-half is-vcentered' style={{marginTop: '0px'}}>
                            <TrabbeImage src={Logo} />
                            <div className='spacing'>
                            <TrabbeComunityContent>
                                Somos uma comunidade preocupada em melhorar a vida das pessoas, trazendo novas oportunidades delas mostrarem seu potencial e se desenvolverem sempre com autonomia e confiança, quando e de onde estiverem.
                            </TrabbeComunityContent>
                            <TrabbeComunityContent>
                                Acreditamos na transformação do relacionamento de profissionais e empresas para torná-lo cada vez mais descomplicado e seguro.​
                            </TrabbeComunityContent>
                            </div> 
                        </div>
                        <div className='column'>
                            <CellPhone src={CellPhoneImage} />
                            <ButterFly src={ButtlerFlyImage} />
                        </div>
                        </div>
                    </div> 
                </div>
                <Butterflies className='mb-5 is-hidden-mobile' src={ButterFliesImage} />
            </section>
            <TrabbeVideo backGround={BackgroundComunity}
                 class="hero is-light is-fullheight">
                    
                <div class="hero-body">
                        <RoseLeaf className='is-hidden-mobile' src={RoseLeafImage} />
                    <div class='container	'>
                        <div class='column is-half is-vcentered is-fullwidth is-flex is-flex-direction-column is-align-items-center column-width'>
                        <TrabbeTitle>O trabalho do <span>futuro</span> hoje!</TrabbeTitle>
                        <YoutubeVideo />
                        </div>
                    </div> 
                </div>
            </TrabbeVideo>
        </Content>
            )
        }


export default HomePage