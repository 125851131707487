import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import { colors } from 'utils/colors';







export const ByxHeader = styled(motion.div)`
    background: url(${props => props.backGround});
    background-size: contain;
`;


export const WrapperCorp = styled(motion.div)`
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    text-align: left;
`;

export const TitleCorp = styled(motion.h1)`
    font-size: 36px;
    font-weight: bold;

    

`;

export const SubTitle = styled(motion.h2)`
    font-size: 30px;
    margin-top: 16px;
    font-weight: 300;
    max-width: 600px;
    line-height: 2.5rem;

    span {
        font-weight: bold;
    }

`;

export const Paragraphy = styled(motion.p)`
    font-size: 22px;
    font-weight: 400;
    margin-top: 16px;
    max-width: 600px;
`;


export const ByxImage = styled(motion.img)`
    position: relative;
    top: 65px;
`;


export const WrapperAI = styled(motion.div)`
    background: url(${props => props.backGround});
    color: ${colors.white};
    background-size: cover;
    text-align: center !important;
`;

export const Conjunto = styled(motion.img)`
    width: 1440px;
    position: relative;
    top: 0px;

`;


export const TitleAI = styled(motion.h1)`
    font-size: 40px;
    font-weight: bold;
    margin-top: 37px;
    text-align: left;
`;


export const AIParagraphy = styled(motion.p)`
    font-size: 24px;
    font-weight: 400;
    margin-top: 16px;
    text-align: left;

`;

export const WrapperEvolution = styled.div`
    width: 100%;
    color: ${colors.white};
    text-align: center;
`;


export const Rift = styled.img`
    transform: scale(.65,.65) !important;
    -webkit-transform: scale(.65,.65) !important;
    -moz-transform: scale(.65,.65) !important;
    -ms-transform: scale(.65,.65) !important;
    -o-transform: scale(.65,.65) !important;
    position: absolute;
    bottom: -255px;
    left: -420px;
`;





export const TitleEvolution = styled(motion.h1)`
    font-size: 40px;
    color: #000;
    margin-top: 60px;
    margin-bottom: 60px;
    font-weight: bold;
    text-align: center;


    span {
        background: transparent linear-gradient(108deg, #DC6C61 0%, #381679 100%) 0% 0% no-repeat padding-box;
        -webkit-background-clip: text;
        color: transparent;
    }
`;

const fadeInOut = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const EvolutionTextType = styled.p`
    text-align: left;
    font: italic normal normal 24px/32px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    position: absolute;
    animation: ${props => (props.isActive ? fadeInOut : 'none')} 200ms ease;
    transition: all ease-in-out 300ms;
    top: 60px;

    
    @media only screen and (min-width: 250px) and (max-width: 769px){
        position: relative;
        margin-bottom: 40px;
    }
    @media only screen and (min-width: 770px) and (max-width: 1000px){
        position: absolute;
        top: 520px;
    }

    @media only screen and (min-width: 1001px) and (max-width: 1200px){
        position: absolute;
        top: 420px;
    }

    @media only screen and (min-width: 1201px) and (max-width: 1260px){
        position: absolute;
        top: 450px;
    }

    @media only screen and (min-width: 1261px) and (max-width: 1368px){
        position: absolute;
        top: 490px;
    }

    @media only screen and (min-width: 1369px){
        position: absolute;
        top: 470px;
    }
`;

export const WrapperImageTypeEvolution = styled.div`
    position: relative;
    text-align: center;
    cursor: pointer;
`;


export const EvolutionImageType = styled.img`
    opacity: ${props => props.isActive ? 1 : 0.4};
    animation: ${props => (props.isActive ? fadeInOut : 'none')} 0.5s ease;
`;

export const TextTypeMobile = styled.div`
    left: 0px;
    bottom: 250px;
    text-align: center;
    font: normal normal normal 20px/26px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    transition: all ease-in-out 300ms;
    cursor: pointer;
    margin-bottom: 20px;
    display: block;

    @media only screen and (min-width: 200px) and (max-width: 1235px){
        display: block;
    }
    @media only screen and (min-width: 1236px){
        display: none;
    }
`;



export const EvolutionImageTypeText = styled.p`
    position: absolute;
    left: -140px;
    bottom: -60px;
    text-align: center;
    font: normal normal normal 20px/26px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: ${props => props.isActive ? 'block' : 'none'};
    width: 400px;
    animation: ${props => (props.isActive ? fadeInOut : 'none')} 0.5s ease;
    transition: all ease-in-out 300ms;
    cursor: pointer;

    @media only screen and (min-width: 200px) and (max-width: 1235px){
        display: none;
        left: -140px;
    }
    
    @media only screen and (min-width: 1236px){
        left: -157px;
        display: ${props => props.isActive ? 'block' : 'none'};
    }
    
`;

export const People = styled(motion.img)`
    position: absolute;
    bottom: 34px;
    display: none;

`;


export const BackgroundEvolution = styled(motion.div)`
    width: 100%;
    height: 100vh;
    background: url(${props => props.backGround}) no-repeat;
    color: ${colors.white};
    text-align: center;
`;


export const TitleEvolutionContent = styled(motion.div)`
    max-width: 650px;
    margin-right: 0px;
    font-weight: bold;
    text-align: left;
    position: relative;
`;

export const TitleEvolutionH = styled(motion.h1)`
    font-size: 40px;
    font-weight: bold;
    text-align: left;
`;

export const ContentEvolution = styled(motion.div)`
    font-size: 24px;
    font-weight: 400;
    margin-top: 16px;
    text-align: left;
    max-width: 540px;

    span {
        font-weight: bold;
    }

    
`;




export const Content = styled(motion.div)`
    width: 100%;
    position: relative;
    scroll-behavior: smooth;

    @media only screen and (min-width: 250px){

        ${TitleEvolutionContent} {
            display: flex;
            justify-content: center;
            
        }
        
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 32px !important;
        }

        ${SubTitle} {
            font-size: 32px !important;
        }

        
        ${Paragraphy} {
            font-size: 20px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 32px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 32px !important;
        }

        ${AIParagraphy} {
            font-size: 20px !important;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 18px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 32px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 32px;
        }

        ${ContentEvolution} {
            font-size: 20px;
        }

    }

    @media only screen and (min-width: 375px){
        
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 32px !important;
        }

        ${SubTitle} {
            font-size: 32px !important;
        }

        
        ${Paragraphy} {
            font-size: 20px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 32px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 32px !important;
        }

        ${AIParagraphy} {
            font-size: 20px !important;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 18px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 32px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 32px;
        }

        ${ContentEvolution} {
            font-size: 20px;
        }

    }

    @media only screen and (min-width: 425px){
        
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 32px !important;
        }

        ${SubTitle} {
            font-size: 32px !important;
        }

        
        ${Paragraphy} {
            font-size: 20px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 32px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 32px !important;
        }

        ${AIParagraphy} {
            font-size: 20px !important;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 18px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 32px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 32px;
        }

        ${ContentEvolution} {
            font-size: 20px;
        }

    }

    @media only screen and (min-width: 425px){
        
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 32px !important;
        }

        ${SubTitle} {
            font-size: 32px !important;
        }

        
        ${Paragraphy} {
            font-size: 20px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 32px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 32px !important;
        }

        ${AIParagraphy} {
            font-size: 20px !important;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 18px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 32px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 32px;
        }

        ${ContentEvolution} {
            font-size: 20px;
        }

    }

    @media only screen and (min-width: 768px){
        
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 175px;
        }

        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -360px;
            bottom: -548px;
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }

        ${SubTitle} {
            font-size: 36px !important;
        }

        
        ${Paragraphy} {
            font-size: 22px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 36px !important;
        }

        ${AIParagraphy} {
            font-size: 22px !important;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 18px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 36px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 36px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

    }

    
    @media only screen and (min-width: 768px) and (min-height: 800px){

        ${ByxImage} {
            top: 175px;
        }

        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -360px;
            bottom: -548px;
        }

}

@media only screen and (min-width: 768px) and (min-height: 1000px){

${ByxImage} {
    top: 325px;
}

${Rift} {
    transform: scale(.8,.8) !important;
    -webkit-transform: scale(0.8,0.8) !important;
    -moz-transform: scale(0.8,0.8) !important;
    -ms-transform: scale(0.8,0.8) !important;
    -o-transform: scale(0.8,0.8) !important;
    left: -360px;
    bottom: -548px;
}

}

@media only screen and (min-width: 768px) and (min-height: 1100px){

${ByxImage} {
    top: 345px;
}

${Rift} {
    transform: scale(.8,.8) !important;
    -webkit-transform: scale(0.8,0.8) !important;
    -moz-transform: scale(0.8,0.8) !important;
    -ms-transform: scale(0.8,0.8) !important;
    -o-transform: scale(0.8,0.8) !important;
    left: -360px;
    bottom: -548px;
}

}

    @media only screen and (min-width: 1024px){

        ${ByxImage} {
            top: 145px;
        }
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -380px;
            bottom: -568px;
        }
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 36px !important;
        }

        ${SubTitle} {
            font-size: 36px !important;
        }

        
        ${Paragraphy} {
            font-size: 22px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 36px !important;
        }

        ${AIParagraphy} {
            font-size: 22px !important;
            max-width: 1200px;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 18px;
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 36px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 36px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

    }

    @media only screen and (min-width: 1024px) and (min-height: 960px){

${ByxImage} {
    top: 225px;
}

${Rift} {
    transform: scale(.8,.8) !important;
    -webkit-transform: scale(0.8,0.8) !important;
    -moz-transform: scale(0.8,0.8) !important;
    -ms-transform: scale(0.8,0.8) !important;
    -o-transform: scale(0.8,0.8) !important;
    left: -380px;
    bottom: -568px;
}

${ByxHeader} {
    justify-content: center !important;

}

${TitleCorp} {
    font-size: 36px !important;
}

${SubTitle} {
    font-size: 36px !important;
}


${Paragraphy} {
    font-size: 22px !important;
}

${AIParagraphy} {
}

${TitleCorp} {
    font-size: 36px !important;
}



${WrapperAI} {
}

${TitleAI} {
    font-size: 36px !important;
}

${AIParagraphy} {
    font-size: 22px !important;
    max-width: 1200px;
}

${BackgroundEvolution} {
}

${ContentEvolution} {
    font-size: 18px;
}

${EvolutionTextType} {
    font-size: 22px;
}

${WrapperEvolution} {
    position: relative;
    
}

${TitleEvolution} {
    font-size: 36px;
}

${TitleEvolutionContent} {

}

${TitleEvolutionH} {
    font-size: 36px;
}

${ContentEvolution} {
    font-size: 22px;
}

}

@media only screen and (min-width: 1024px) and (min-height: 1100){

${ByxImage} {
    top: 375px;
}

${Rift} {
    transform: scale(.8,.8) !important;
    -webkit-transform: scale(0.8,0.8) !important;
    -moz-transform: scale(0.8,0.8) !important;
    -ms-transform: scale(0.8,0.8) !important;
    -o-transform: scale(0.8,0.8) !important;
    left: -380px;
    bottom: -568px;
}

${ByxHeader} {
    justify-content: center !important;

}

${TitleCorp} {
    font-size: 36px !important;
}

${SubTitle} {
    font-size: 36px !important;
}


${Paragraphy} {
    font-size: 22px !important;
}

${AIParagraphy} {
}

${TitleCorp} {
    font-size: 36px !important;
}



${WrapperAI} {
}

${TitleAI} {
    font-size: 36px !important;
}

${AIParagraphy} {
    font-size: 22px !important;
    max-width: 1200px;
}

${BackgroundEvolution} {
}

${ContentEvolution} {
    font-size: 18px;
}

${EvolutionTextType} {
    font-size: 22px;
}

${WrapperEvolution} {
    position: relative;
    
}

${TitleEvolution} {
    font-size: 36px;
}

${TitleEvolutionContent} {

}

${TitleEvolutionH} {
    font-size: 36px;
}

${ContentEvolution} {
    font-size: 22px;
}

}

    @media only screen and (min-width: 1216px){

${ByxImage} {
    top: 200px;
}

${Rift} {
    transform: scale(.8,.8) !important;
    -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
    left: -400px;
    bottom: -590px;
}

${ByxHeader} {
    justify-content: center !important;

}

${TitleCorp} {
    font-size: 36px !important;
}

${SubTitle} {
    font-size: 36px !important;
}


${Paragraphy} {
    font-size: 22px !important;
}

${AIParagraphy} {
}

${TitleCorp} {
    font-size: 36px !important;
}



${WrapperAI} {
}

${TitleAI} {
    font-size: 36px !important;
}

${AIParagraphy} {
    font-size: 22px !important;
    max-width: 1200px;
}

${BackgroundEvolution} {
}

${ContentEvolution} {
            font-size: 20px;
            margin-top: 40px;
        }

${EvolutionTextType} {
            font-size: 20px;
}

${WrapperEvolution} {
    position: relative;
    
}

${TitleEvolution} {
    font-size: 36px;
}

${TitleEvolutionContent} {

}

${TitleEvolutionH} {
    font-size: 36px;
}

${ContentEvolution} {
    font-size: 22px;
}

}

    @media only screen and (min-width: 1240px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -450px;
            bottom: -538px;
        }
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 90px;
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }

        ${SubTitle} {
            font-size: 36px !important;
        }

        
        ${Paragraphy} {
            font-size: 22px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 36px !important;
        }

        ${AIParagraphy} {
            font-size: 22px !important;
            max-width: 1200px;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 20px;
            margin-top: 100px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 36px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 36px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

    }

    @media only screen and (min-width: 1240px) and (min-height: 960px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -450px;
            bottom: -538px;
        }
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 210px;
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }

        ${SubTitle} {
            font-size: 36px !important;
        }

        
        ${Paragraphy} {
            font-size: 22px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 36px !important;
        }

        ${AIParagraphy} {
            font-size: 22px !important;
            max-width: 1200px;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 20px;
            margin-top: 100px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 36px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 36px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

    }

    @media only screen and (min-width: 1407px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -470px;
            bottom: -567px;
        }
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 100px;
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }

        ${SubTitle} {
            font-size: 36px !important;
        }

        
        ${Paragraphy} {
            font-size: 22px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 36px !important;
        }

        ${AIParagraphy} {
            font-size: 22px !important;
            max-width: 1200px;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 20px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 36px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 36px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

    }

    @media only screen and (min-width: 1407px) and (min-height: 900px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -470px;
            bottom: -597px;
        }
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 220px;
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }

        ${SubTitle} {
            font-size: 36px !important;
        }

        
        ${Paragraphy} {
            font-size: 22px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 36px !important;
        }



        ${WrapperAI} {
        }

        ${TitleAI} {
            font-size: 36px !important;
        }

        ${AIParagraphy} {
            font-size: 22px !important;
            max-width: 1200px;
        }

        ${BackgroundEvolution} {
        }

        ${ContentEvolution} {
            font-size: 20px;
        }

        ${EvolutionTextType} {
            font-size: 20px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 36px;
        }

        ${TitleEvolutionContent} {

        }

        ${TitleEvolutionH} {
            font-size: 36px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

    }

    @media only screen and (min-width: 1440px) and (min-height: 559px){
        
        ${Rift} {
            transform: scale(.7,.7) !important;
            -webkit-transform: scale(.7,.7) !important;
            -moz-transform: scale(.7,.7) !important;
            -ms-transform: scale(.7,.7) !important;
            -o-transform: scale(.7,.7) !important;
            left: -470px !important;
            bottom: -580px !important;
            
        }

        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 40px !important;
        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        
        ${Paragraphy} {
            font-size: 24px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }



        ${WrapperAI} {
        }

        ${Conjunto} {
            top: 10px;
        }

        ${TitleAI} {
            font-size: 40px !important;
        }

        ${AIParagraphy} {
            font-size: 24px !important;
            max-width: 1540px;
        }

        ${BackgroundEvolution} {
            background-size: cover !important;
            padding: 20px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 40px;
            width: 100%;
            text-align: center;
        }

        ${TitleEvolutionContent} {
            font-size: 24px;

        }

        ${TitleEvolutionH} {
        }

        ${ContentEvolution} {
        }

    }

    @media only screen and (min-width: 1440px) and (min-height: 866px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -470px !important;
            bottom: -508px !important;
            
        }

        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 40px !important;
        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        
        ${Paragraphy} {
            font-size: 24px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }



        ${WrapperAI} {
        }

        ${Conjunto} {
            top: 10px;
        }

        ${TitleAI} {
            font-size: 40px !important;
        }

        ${AIParagraphy} {
            font-size: 24px !important;
            max-width: 1540px;
        }

        ${BackgroundEvolution} {
            background-size: cover !important;
            padding: 20px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 40px;
            width: 100%;
            text-align: center;
        }

        ${TitleEvolutionContent} {
            font-size: 24px;

        }

        ${TitleEvolutionH} {
        }

        ${ContentEvolution} {
        }

    }

    @media only screen and (min-width: 1440px) and (min-height: 1000px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -470px !important;
            bottom: -628px !important;
            
        }

        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 40px !important;
        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        
        ${Paragraphy} {
            font-size: 24px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }



        ${WrapperAI} {
        }

        ${Conjunto} {
            top: 10px;
        }

        ${TitleAI} {
            font-size: 40px !important;
        }

        ${AIParagraphy} {
            font-size: 24px !important;
            max-width: 1540px;
        }

        ${BackgroundEvolution} {
            background-size: cover !important;
            padding: 20px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 40px;
            width: 100%;
            text-align: center;
        }

        ${TitleEvolutionContent} {
            font-size: 24px;

        }

        ${TitleEvolutionH} {
        }

        ${ContentEvolution} {
        }

    }

    @media only screen and (min-width: 1680px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -470px !important;
            bottom: -628px !important;
            
        }

        ${ByxHeader} {
            justify-content: center !important;

        }

        ${TitleCorp} {
            font-size: 40px !important;
        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        
        ${Paragraphy} {
            font-size: 24px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }



        ${WrapperAI} {
        }

        ${Conjunto} {
            top: 10px;
        }

        ${TitleAI} {
            font-size: 40px !important;
        }

        ${AIParagraphy} {
            font-size: 24px !important;
            max-width: 1540px;
        }

        ${BackgroundEvolution} {
            background-size: cover !important;
            padding: 20px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 40px;
            width: 100%;
            text-align: center;
        }

        ${TitleEvolutionContent} {
            font-size: 24px;

        }

        ${TitleEvolutionH} {
        }

        ${ContentEvolution} {
        }

    }

    @media only screen and (min-width: 1920px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8);
            -moz-transform: scale(0.8,0.8);
            -ms-transform: scale(0.8,0.8);
            -o-transform: scale(0.8,0.8);
            left: -530px !important;
            bottom: -620px !important;
            bottom: -670px !important;
        }
            
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 205px;
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        
        ${Paragraphy} {
            font-size: 24px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }



        ${WrapperAI} {
        }

        ${Conjunto} {
            top: 45px;
        }

        ${TitleAI} {
            font-size: 40px !important;
        }

        ${AIParagraphy} {
            font-size: 24px !important;
            max-width: 1500px;
        }

        ${BackgroundEvolution} {
            background-size: cover !important;
            padding: 20px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${TitleEvolution} {
            font-size: 40px;
            width: 100%;
            text-align: center;
        }

        ${TitleEvolutionContent} {
            font-size: 24px;

        }

        ${TitleEvolutionH} {
        }

        ${ContentEvolution} {
        }

    }

    @media only screen and (min-width: 2560px){
        
        ${Rift} {
            transform: scale(.8,.8) !important;
            -webkit-transform: scale(0.8,0.8) !important;
            -moz-transform: scale(0.8,0.8) !important;
            -ms-transform: scale(0.8,0.8) !important;
            -o-transform: scale(0.8,0.8) !important;
            left: -530px !important;
            bottom: -638px !important;
        }
            
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 145px;
            transform: scale(1) !important;
            -webkit-transform: scale(1) !important;
            -moz-transform: scale(1) !important;
            -ms-transform: scale(1) !important;
            -o-transform: scale(1) !important;
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        
        ${Paragraphy} {
            font-size: 24px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }



        ${WrapperAI} {
        }

        ${Conjunto} {
            top: 45px;
        }

        ${TitleAI} {
            font-size: 40px !important;
        }

        ${AIParagraphy} {
            font-size: 24px !important;
            max-width: 1500px;
        }

        ${BackgroundEvolution} {
            background-size: cover !important;
            padding: 20px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${TitleEvolution} {
            font-size: 40px;
            width: 100%;
            text-align: center;
        }

        ${TitleEvolutionContent} {
            font-size: 24px;

        }

        ${TitleEvolutionH} {
        }

        ${ContentEvolution} {
        }

    }
    @media only screen and (min-width: 2560px) and (min-height: 1000px){
        
        ${Rift} {
            transform: scale(.9) !important;
            -webkit-transform: scale(.9) !important;
            -moz-transform: scale(.9) !important;
            -ms-transform: scale(.9) !important;
            -o-transform: scale(.9) !important;
            left: -630px !important;
            bottom: -698px !important;
        }
            
        
        ${ByxHeader} {
            justify-content: center !important;

        }

        ${ByxImage} {
            top: 215px;
            transform: scale(1.5) !important;
            -webkit-transform: scale(1.5) !important;
            -moz-transform: scale(1.5) !important;
            -ms-transform: scale(1.5) !important;
            -o-transform: scale(1.5) !important;        
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }

        ${SubTitle} {
            font-size: 40px !important;
        }

        
        ${Paragraphy} {
            font-size: 24px !important;
        }

        ${AIParagraphy} {
        }

        ${TitleCorp} {
            font-size: 40px !important;
        }



        ${WrapperAI} {
        }

        ${Conjunto} {
            top: 45px;
        }

        ${TitleAI} {
            font-size: 40px !important;
        }

        ${AIParagraphy} {
            font-size: 24px !important;
            max-width: 1500px;
        }

        ${BackgroundEvolution} {
            background-size: cover !important;
            padding: 20px;
        }

        ${ContentEvolution} {
            font-size: 22px;
        }

        ${WrapperEvolution} {
            position: relative;
            
        }

        ${EvolutionTextType} {
            font-size: 22px;
        }

        ${TitleEvolution} {
            font-size: 40px;
            width: 100%;
            text-align: center;
        }

        ${TitleEvolutionContent} {
            font-size: 24px;

        }

        ${TitleEvolutionH} {
        }

        ${ContentEvolution} {
        }

    }
`;
